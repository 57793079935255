import React, {useState} from 'react';
import {useCheckoutState} from "../../../../Checkout";
import {LoadingIcon, Translate, useWindowSize} from "../../../../../general";
import {useProductAttributes} from "../../../state";

export const AddToCartButton = () => {
    const {isTablet} = useWindowSize();

    const {handleAddToCart, addingToCart, errorMessage} = useCheckoutState();
    const [qty] = useState(1);

    const {
        loading,
        productSku,
        isInAddToCartCategory
    } = useProductAttributes() || {};

    if (loading) {
        return null;
    }

    return (
        <div className="p-5 md:bg-inherit flex items-center justify-center md:justify-start gap-5">
            {isInAddToCartCategory && (
                <div className={'mt-0 mb-3 md:mt-5 md:mb-5 flex w-full md:w-auto justify-center md:justify-start  flex-col'}>
                    <div className={'react-addToCart flex justify-center md:justify-start w-full max-w-full md:max-w-[500px]'}>

                            <div className={'flex flex-row gap-4 w-full items-center justify-center'}>
                                <button
                                    className={'h-[45px] font-font-p  tracking-wide text-2xl font-bold p-4 w-auto bg-olive-color ' +
                                        'hover:bg-olive-color hover:text-white text-white font-bold py-2 px-4 ' +
                                        'focus:bg-olive-color active:olive-color focus:text-white active:text-white ' +
                                        'flex items-center transition duration-300 ease-in-out transform hover:scale-105 ' +
                                        'border-0 hover:border-0 flex justify-center items-center'}
                                    onClick={() => handleAddToCart({sku: productSku, quantity: qty})}
                                >
                                    {productSku === addingToCart ? (
                                        <LoadingIcon/>
                                    ) : (
                                        <>
                                                        <span
                                                            className={'fold:hidden font-font-p  tracking-wide text-2xl font-black'}>{'Adauga in cos'}
                                                        </span>
                                            <svg className={'ml-1 md:ml-5'}
                                                 xmlns="http://www.w3.org/2000/svg" height="24" width="24"
                                                 viewBox="0 0 576 512">
                                                <path fill="#fff"
                                                      d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                                            </svg>
                                        </>
                                    )}
                                </button>
                            </div>
                    </div>
                    {errorMessage && (
                        <div className={'font-font-s text-red-500 text-xl mt-3'}>
                            <Translate message={errorMessage}/>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
