import React from "react";
import {ThemeProvider} from "../../state";
import {client} from "../../../Infrastructure/models/client";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {FooterInfo} from "./assets/FooterInfo";
import {FooterLinks} from "./assets/FooterLinks";
import {SocialLinks} from "./assets/SocialLinks";
import {NewsLetterForm} from "./assets/NewsLetterForm";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000, // 5 minutes
            cacheTime: 30 * 60 * 1000, // 30 minutes
            retry: 2,
            retryDelay: 1000,
            refetchOnWindowFocus: false,
        },
    },
});

export const FooterContainer = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider>
                <div className={'flex flex-col gap-10 items-center '}>
                    <div className={'w-full flex flex-col md:flex-row justify-between items-center md:items-start gap-3'}>
                        <FooterLinks/>
                        <NewsLetterForm/>
                    </div>
                    <div className={'w-full flex flex-col gap-10'}>
                        <FooterInfo/>
                        <SocialLinks/>
                    </div>
                </div>
            </ThemeProvider>
        </QueryClientProvider>
    )
}
