import React, {useEffect, useState} from 'react';
import {GridTestProvider, useGridTest} from "../../state";
import {client} from "../../../Infrastructure/models/client";
import {QueryClientProvider} from "@tanstack/react-query";
import {GridTest} from "./RoTests/types/GridTest";

const queryClient = client();

export const AdminGridForm: React.FC<{ product_id: number; productSku: string }> = ({product_id, productSku}) => {
    const {saveTest, gridTestData, loading} = useGridTest();

    const [localTest, setLocalTest] = useState(() => ({
        product_id,
        productSku,
        status: 1,
        questions: Array(20).fill(null).map((_, index) => ({
            question_id: index + 1,
            product_id,
            questionText: '',
            answer1: '',
            answer2: '',
            answer3: '',
            answer4: '',
            correctAnswer: 1,
        })),
    }));

    useEffect(() => {
        if (gridTestData?.gridTest) {
            const { gridTest, gridQuestions } = gridTestData;
            setLocalTest({
                product_id,
                productSku,
                status: gridTest.status,
                questions: gridQuestions.map((q) => ({
                    question_id: q.question_id,
                    product_id: q.product_id,
                    questionText: q.questionText || '',
                    answer1: q.answer1 || '',
                    answer2: q.answer2 || '',
                    answer3: q.answer3 || '',
                    answer4: q.answer4 || '',
                    correctAnswer: q.correctAnswer || 1,
                })),
            });
        }
    }, [gridTestData, product_id, productSku]);

    const handleSave = async () => {
        if (localTest.questions.some(q => !q.questionText || !q.answer1 || !q.answer2 || !q.answer3 || !q.answer4)) {
            alert('Toate întrebările și răspunsurile trebuie completate!');
            return;
        }

        try {
            await saveTest({
                product_id: localTest.product_id,
                productSku: localTest.productSku,
                status: localTest.status,
                questions: localTest.questions,
            });
            alert('Test salvat cu succes!');
        } catch (error) {
            alert('Eroare la salvare: ' + error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            handleSave();
        }}>
            <div className="page-main-actions">
                <div className="page-actions">
                    <button
                        className="action-secondary back"
                        type="button"
                        onClick={() => window.history.back()}
                    >
                        Back
                    </button>
                    <button className="action-primary save" type="submit">
                        Save Test
                    </button>
                </div>
            </div>

            <div className="admin__fieldset-wrapper">
                <fieldset>
                    <div className="admin__field">
                        <label>Status</label>
                        <select
                            name="status"
                            value={localTest.status}
                            onChange={(e) =>
                                setLocalTest({...localTest, status: Number(e.target.value)})
                            }
                        >
                            <option value="1">Enabled</option>
                            <option value="0">Disabled</option>
                        </select>
                    </div>
                </fieldset>
            </div>
            <GridTest
                questions={localTest.questions}
                setQuestions={(questions) => setLocalTest({...localTest, questions})}
            />
        </form>
    );
};

export const AdminGridFormWrapper: React.FC<{ product_id: number; productSku: string }> = (props) => (
    <QueryClientProvider client={queryClient}>
        <GridTestProvider product_id={props.product_id}>
            <AdminGridForm {...props} />
        </GridTestProvider>
    </QueryClientProvider>
);
