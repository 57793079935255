import React, { useState, useEffect } from 'react';
import { Lock, Unlock, Play } from 'lucide-react';

declare global {
    interface Window {
        BASE_URL: string;
    }
}

export const VideoLoginPrompt = () => {
    const [isLocked, setIsLocked] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsLocked(prev => !prev);
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    const loginUrl = `${window.BASE_URL}customer/account/login`;
    const createAccountUrl = `${window.BASE_URL}customer/account/create`;

    return (
        <div className="w-full flex flex-col items-center justify-center p-4 md:p-8">
            <div className="bg-card-bg shadow-round-shadow flex flex-col items-center justify-center p-8 w-full md:w-2/3">
                <div className="mb-6 relative flex items-center">
                    {isLocked ? (
                        <Lock className="w-16 h-16 text-red-500 animate-bounce"/>
                    ) : (
                        <Unlock className="w-16 h-16 text-olive-color animate-pulse"/>
                    )}
                    <Play className="w-12 h-12 text-gray-400 ml-4" />
                </div>
                <h3 className="text-2xl font-bold font-font-p  tracking-wide mb-4 text-text-color">
                    Conținut video protejat
                </h3>
                <p className="text-xl font-semibold text-center text-text-color mb-6">
                    Pentru a viziona orice lectie video este necesară autentificarea în cont.
                </p>
                <div className="flex flex-col md:flex-row gap-4">
                    <a href={loginUrl} className="no-underline hover:no-underline">
                        <button
                            className="bg-olive-color hover:bg-olive-color text-white font-bold py-2 px-6 hover:text-white
                                flex items-center transition duration-300 ease-in-out transform hover:scale-105">
                            Autentificare
                        </button>
                    </a>
                    <a href={createAccountUrl} className="no-underline hover:no-underline">
                        <button
                            className="bg-white border-2 border-olive-color text-olive-color hover:text-white
                                hover:bg-olive-color font-bold py-2 px-6 flex items-center
                                focus:bg-olive-color active:olive-color focus:text-white active:text-white
                                transition duration-300 ease-in-out transform hover:scale-105">
                            Creează cont
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};
