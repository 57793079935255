import React from 'react';
import {GalleryRender} from "./ProductMedia/GalleryRender";
import {ProductAttributeProvider} from "../../state";
import {CheckoutProvider} from "../../../Checkout";
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";

interface ProductSku {
    productSku: string;
}

const queryClient = client();

export const ProductGallery: React.FC<ProductSku> = ({productSku}) => {
    return (
        <QueryClientProvider client={queryClient}>
            <CheckoutProvider>
                <ProductAttributeProvider productSku={productSku} config={{}}>
                    <div className={'w-full md:max-w-[1440px] flex flex-col justify-center items-center'}>
                        <GalleryRender/>
                    </div>
                </ProductAttributeProvider>
            </CheckoutProvider>
        </QueryClientProvider>
    );
};
