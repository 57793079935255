import React, {createContext, useContext, useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {request} from '../../../Infrastructure';
import {
    getProductAttributes,
    saveGridTestData,
    getGridTestData,
    getMultipleAnswerData,
    saveMultipleAnswerData,
    getTestCategories,
} from '../../queries';

interface Question {
    id: number;
    text: string;
    answer: boolean;
}

interface TrueOrFalseSubpunct {
    id: number;
    text: string;
    questions: Question[];
}

interface TrueOrFalseData {
    product_id: number;
    product_sku?: string;
    trueOrFalse: TrueOrFalseSubpunct[];
}

//Grid Test
interface GridAnswer {
    id: number;
    text: string;
    checked: boolean;
}

interface GridSubpunct {
    id: number;
    text: string;
    subpuncte: {
        id: number;
        text: string;
        answer: GridAnswer[];
    }[];
}

interface GridData {
    product_id: number;
    product_sku?: string;
    gridTestData: GridSubpunct[];
}

//MultipleAnswer
interface Answer {
    id: number;
    words: string[];
}

interface Subpunct {
    id: number;
    text: string;
    answers: [Answer, Answer];
}

interface MultipleAnswerSubpunct {
    id: number;
    text: string;
    subpuncte: Subpunct[];
}

interface MultipleAnswerData {
    product_id: number;
    product_sku?: string;
    multipleAnswer: MultipleAnswerSubpunct[];
}

//ProductData
interface ProductCategory {
    id: string;
    name: string;
    url_path: string;
}

interface ProductData {
    id: string;
    sku: string;
    name: string;
    categories: ProductCategory[];
}

//Test Category Selection
interface TestCategory {
    id: string;
    name: string;
}

interface TestCategories {
    trueFalse: TestCategory;
    multipleAnswers: TestCategory;
    gridTest: TestCategory;
    nationalEvaluation: TestCategory;
}

interface TestDataState {
    gridTestData: GridData | null;
    multipleAnswerData: MultipleAnswerData | null;
    handleSaveGridTest: (gridTestData: GridData, onSuccess?: () => void) => void;
    handleSaveMultipleAnswer: (multipleAnswerData: MultipleAnswerData, onSuccess?: () => void) => void;
    productData: ProductData | null;
    testCategory: TestCategories | null;
    categories: ProductCategory[] | null;
    loading: boolean;
    error: Error | unknown;
}

const TestDataContext = createContext<TestDataState  | undefined>(undefined);

export const TestDataProvider: React.FC<{ children: React.ReactNode, productId: number, sku: string }> = (
    {
        children,
        productId,
        sku
    }) => {
    const [productData, setProductData] = useState<ProductData | null>(null);
    const [gridTestData, setGridTestData] = useState<GridData | null>(null);
    const [multipleAnswerData, setMultipleAnswerData] = useState<MultipleAnswerData | null>(null);
    const [testCategory, setTestCategory] = useState<TestCategories | null>(null);
    const categories = productData?.categories || null;

    const gridTestMutation = useMutation(
        (variables: { input: GridData }) => request(saveGridTestData, variables),
        {
            onSuccess: (data) => {
                setGridTestData(data.saveGridTestData);
            },
        }
    );

    const multipleAnswerMutation = useMutation(
        (variables: { input: MultipleAnswerData }) => request(saveMultipleAnswerData, variables),
        {
            onSuccess: (data) => {
                setMultipleAnswerData(data.saveMultipleAnswerData);
            },
        }
    );

    const handleSaveGridTest = (gridData: GridData, onSuccess?: () => void) => {
        gridTestMutation.mutate({
            input: {
                product_id: productId,
                gridTestData: gridData.gridTestData
            }
        }, {
            onSuccess: (data) => {
                setGridTestData(data.saveGridTestData);
                if (onSuccess) {
                    onSuccess();
                }
            },
        });
    };

    const handleSaveMultipleAnswer = (multipleAnswerData: MultipleAnswerData, onSuccess?: () => void) => {
        multipleAnswerMutation.mutate({
            input: {
                product_id: productId,
                multipleAnswer: multipleAnswerData.multipleAnswer
            }
        }, {
            onSuccess: (data) => {
                setMultipleAnswerData(data.saveMultipleAnswerData);
                if (onSuccess) {
                    onSuccess();
                }
            },
        });
    };

    const productAttributesQuery = useQuery({
        queryKey: ['productAttributes', sku],
        queryFn: async () => {
            return request(getProductAttributes, { sku});
        },
        enabled: !!sku,
        refetchOnWindowFocus: false,
    });

    const testCategorySelectionQuery = useQuery({
        queryKey: ['testCategory', productId],
        queryFn: async () => {
            const result = await request(getTestCategories);
            return result.data?.testCategories ?? null;
        },
        enabled: !!sku,
        refetchOnWindowFocus: false,
    });

    const gridTestQuery = useQuery({
        queryKey: ['gridTestData', productId],
        queryFn: async () => {
            const result = await request(getGridTestData, {product_id: productId});
            return result.data?.getGridTestData ?? null;
        },
        enabled: !!productId,
        refetchOnWindowFocus: false,
    });

    const multipleAnswerQuery = useQuery({
        queryKey: ['multipleAnswerData', productId],
        queryFn: async () => {
            const result = await request(getMultipleAnswerData, {product_id: productId});
            return result.data?.getMultipleAnswerData ?? null;
        },
        enabled: !!productId,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (testCategorySelectionQuery.data) {
            setTestCategory(testCategorySelectionQuery?.data);
        }
        if (gridTestQuery.data) {
            setGridTestData(gridTestQuery?.data);
        }

        if (multipleAnswerQuery.data) {
            setMultipleAnswerData(multipleAnswerQuery.data);
        }

        if (productAttributesQuery.data) {
            setProductData(productAttributesQuery?.data?.data?.products.items[0]);
        }

    }, [
        productAttributesQuery.data,
        gridTestQuery.data,
        multipleAnswerQuery.data,
        testCategorySelectionQuery.data
    ]);

    return (
        <TestDataContext.Provider value={{
            gridTestData,
            multipleAnswerData,
            handleSaveGridTest,
            handleSaveMultipleAnswer,
            productData,
            testCategory,
            categories,
            error: gridTestMutation.error || multipleAnswerMutation.error || testCategorySelectionQuery.error || productAttributesQuery.error,
            loading: gridTestQuery.isLoading || multipleAnswerQuery.isLoading || testCategorySelectionQuery.isLoading || productAttributesQuery.isLoading,
        }}>
            {children}
        </TestDataContext.Provider>
    );
};

export const useTestDataState = () => {
    const context = useContext(TestDataContext);
    if (context === undefined) {
        throw new Error("useTestDataState must be used within a TestDataProvider");
    }
    return context;
};
