import React, {useState, useEffect} from 'react';
import {useHomepageContextProvider} from '../../../state';
import {useWindowSize} from '../../../../../general/useWindowSize';
import {LoadingIcon} from '../../../../../general/assets/LoadingIcon';
import {ImageSlider} from './ImageSlider';
import {CustomMarquee} from "./CustomMarquee";

function decodeHtml(html: string) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

function extractImageData(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const figures = doc.querySelectorAll('figure');

    return Array.from(figures).map(figure => {
        const link = figure.querySelector('a');
        const img = figure.querySelector('img.pagebuilder-mobile-hidden');
        return {
            src: img?.getAttribute('src') || '',
            alt: img?.getAttribute('alt') || '',
            title: link?.getAttribute('title') || '',
            link: link?.getAttribute('href') || '#'
        };
    });
}

export const CmsBlocks: React.FC = () => {
    const {blockData1, blockData2, blockData3, blockData4, loading} = useHomepageContextProvider();
    const [activeTab, setActiveTab] = useState(0);

    const tabs = [
        {name: blockData2?.title, content: blockData2},
        {name: blockData3?.title, content: blockData3},
        {name: blockData4?.title, content: blockData4},
    ];

    const renderBlockContent = (blockData: any) => {
        if (!blockData || !blockData.content) return null;
        return <div dangerouslySetInnerHTML={{__html: decodeHtml(blockData.content)}}></div>;
    };

    if (loading) {
        return <LoadingIcon/>;
    }

    // Procesăm datele pentru ImageSlider
    const slides = blockData1?.content ? extractImageData(decodeHtml(blockData1.content)) : [];

    return (
        <div className="mb-12 w-full flex flex-col items-center">
            <div className="w-full">
                <div className="homepage-block1 mb-5">
                    <ImageSlider slides={slides}/>
                </div>
                <div className="homepage-info-buttons flex flex-col items-center justify-center mb-4">
                    <CustomMarquee text="PREZENTARE"/>
                    <div className="w-2/3 flex flex-col xl:flex-row gap-5 items-center justify-center min-h-[100px]">
                        {tabs.map((tab, index) => (
                            <button
                                key={index}
                                onClick={() => setActiveTab(index)}
                                className={`relative w-full xl:w-1/3 min-h-[80px] px-6 py-4 text-xl font-bold
                                    overflow-hidden transition-all duration-300 transform
                                    ${activeTab === index
                                    ? 'bg-orange-700 text-white translate-y-[-4px]'
                                    : 'bg-stone-700 text-white hover:bg-stone-600'
                                }
                                    hover:translate-y-[-2px]
                                    active:translate-y-0
                                    group
                                  `}
                            >
                                {/* Minecraft command block patterns */}
                                <div className="absolute inset-0 opacity-10">
                                    <div className="absolute top-0 left-0 w-full h-1 bg-white"></div>
                                    <div className="absolute bottom-0 left-0 w-full h-1 bg-black"></div>
                                    <div className="absolute top-0 left-0 w-1 h-full bg-white"></div>
                                    <div className="absolute top-0 right-0 w-1 h-full bg-black"></div>
                                </div>

                                {/* Animated circuits/patterns */}
                                <div className={`
                                    absolute inset-0
                                    opacity-20
                                    pointer-events-none
                                    ${activeTab === index ? 'animate-pulse' : ''}
                                  `}>
                                    <div className="absolute top-2 left-2 w-2 h-2 bg-current rounded-full"></div>
                                    <div className="absolute top-2 right-2 w-2 h-2 bg-current rounded-full"></div>
                                    <div className="absolute bottom-2 left-2 w-2 h-2 bg-current rounded-full"></div>
                                    <div className="absolute bottom-2 right-2 w-2 h-2 bg-current rounded-full"></div>
                                </div>

                                {/* Hover effect overlay */}
                                <div className={`
                                        absolute inset-0
                                        bg-gradient-to-r from-transparent via-white to-transparent
                                        opacity-0
                                        group-hover:opacity-10
                                        transition-opacity
                                        duration-300
                                        -translate-x-full
                                        group-hover:translate-x-full
                                        transform
                                      `}>
                                </div>

                                {/* Active/Selected indicator */}
                                {activeTab === index && (
                                    <div className="absolute inset-0 bg-white opacity-5"></div>
                                )}

                                {/* Text container */}
                                <div className="relative z-10 flex items-center justify-center">
                                    <span className={`
                                      transform transition-all duration-300
                                      ${activeTab === index
                                            ? 'scale-110 drop-shadow-lg'
                                            : 'group-hover:scale-105'
                                        }
                                    `}>
                                      {tab.name}
                                    </span>
                                </div>

                                {/* Bottom glow effect */}
                                <div className={`
                                    absolute bottom-0 left-0 right-0 h-1
                                    bg-gradient-to-t from-white to-transparent
                                    opacity-30
                                    transform transition-all duration-300
                                    ${activeTab === index
                                        ? 'scale-100'
                                        : 'scale-0 group-hover:scale-100'
                                    }
                                  `}>
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
                <div className="homepage-block-content text-text-color font-font-s text-xl min-h-[400px]">
                    {renderBlockContent(tabs[activeTab].content)}
                </div>
            </div>
        </div>
    );
};
