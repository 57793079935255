import React from 'react';

export const TeamMembers = () => {
    const basePath = '/static/frontend/Hint4/theme/ro_RO/images/';

    const team = [
        {
            name: "Minionul",
            role: "Developer",
            description: "Creează și menține infrastructura tehnică a platformei, asigurând o experiență de învățare fluidă și interactivă pentru toți utilizatorii.",
            img: "developer.jpg"
        },
        {
            name: "Administrator Content",
            role: "Manager de Conținut",
            description: "Coordonează și organizează materialele educaționale, asigurând calitatea și relevanța conținutului pentru programa școlară.",
            img: "creator-continut.jpg"
        },
        {
            name: "Profesor Limba Română",
            role: "Expert Educațional",
            description: "Cu peste 10 ani de experiență în dezvoltarea de manuale școlare acreditate de minister, contribuie la crearea materialelor educaționale folosite în sute de școli.",
            img: "profesor2.jpg"
        },
        {
            name: "Profesor Limba Română",
            role: "Expert Educațional",
            description: "Specialist în pedagogie modernă cu peste 10 ani de experiență în elaborarea de auxiliare școlare și teste pentru evaluarea elevilor.",
            img: "profesor1.jpg"
        }
    ];

    return (
        <section className="bg-bg-gray py-16 px-8">
            <h2 className="font-font-p  tracking-wide text-4xl text-center text-wood-color mb-12">
                Echipa Noastră
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
                {team.map((member, index) => (
                    <div
                        key={index}
                        className="bg-white p-8   shadow-round-shadow transition-transform duration-300 hover:-translate-y-2"
                    >
                        <div className="w-52 h-52 rounded-full mx-auto mb-6 border-2 border-wood-color overflow-hidden group">
                            <div className="w-full h-full relative transition-transform duration-300 group-hover:scale-110">
                                <img
                                    src={`${basePath}${member.img}`}
                                    alt={member.name}
                                    className="w-48 h-48 rounded-full absolute top-1/2 left-1/2
                                    transform -translate-x-1/2 -translate-y-1/2 border-4 border-wood-color object-cover transition-transform duration-300"
                                />
                            </div>
                        </div>

                        <h3 className="font-font-p  tracking-wide text-2xl text-wood-color mb-2">
                            {member.name}
                        </h3>
                        <p className="text-olive-color font-semibold mb-4">
                            {member.role}
                        </p>
                        <p className="text-xl text-text-color">
                            {member.description}
                        </p>
                    </div>
                ))}
            </div>
        </section>
    );
};
