import React, {useMemo} from 'react';
import {useCategory} from '../../../state';
import {BlockSk} from "../assets/BlockSk";

function decodeHtml(html: string | null) {
    if (!html) return null;
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const SubcategoryData: React.FC = () => {
    const {categoryData, loading, error} = useCategory();

    const decodedSubcategories = useMemo(() => {
        return categoryData?.children.map(subcat => ({
            ...subcat,
            description: decodeHtml(subcat.description)
        }));
    }, [categoryData?.children]);

    if (loading) return <BlockSk/>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className={'flex flex-wrap flex-row justify-start items-start gap-5 mb-3 xl:mb-5'}>
            {decodedSubcategories?.map(subcat => (
                <div
                    key={subcat.id}
                    className={'bg-card-bg flex flex-row xl:flex-col items-center xl:justify-start justify-center ' +
                        'gap-3 p-4 xl:w-[24%] xl:min-h-[356px] xl:min-w-[24%] md:w-[45%] md:min-h-[200px] md:min-w-[45%] ' +
                        'w-full min-h[200px] min-w-[100%] ' +
                        'hover:shadow-round-shadow border-2 border-peru-color hover:border-olive-color '}
                >
                    <a href={`/${subcat.url_path}.html`}
                       className={'w-full hover:cursor:pointer hover:no-underline flex flex-row ' +
                           'xl:flex-col items-start justify-start xl:items-center xl:justify-center gap-3 p-4'}
                    >
                        <div className={'text-center flex flex-col items-center justify-center w-2/5 xl:w-auto gap-3 pr-2 ' +
                            ' border-r-2 border-olive-color xl:border-0 '}>
                            <h3 className="font-font-p  tracking-wide text-text-color text-xl font-bold ">{subcat.name}</h3>
                            {subcat.image && (
                                <img src={subcat.image} alt={subcat.name}
                                     className="w-auto h-auto md:h-40 object-contain"/>
                            )}
                        </div>
                        {subcat.description && (
                            <div
                                className={'font-font-s text-text-color text-xl h-56 xl:h-72 overflow-hidden w-3/5 xl:w-auto'}
                                dangerouslySetInnerHTML={{__html: subcat.description}}
                            />
                        )}
                    </a>
                </div>
            ))}
        </div>
    );
};
