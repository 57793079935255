import React from 'react';
import {Mission} from "./assets/Mission";
import {TeamMembers} from "./assets/TeamMembers";
import {Values} from "./assets/Values";

export const AboutUs = () => {
    return (
        <div className="font-font-s text-text-color">
            <Mission />
            <TeamMembers />
            <Values />
        </div>
    );
};
