import React from 'react';
import {QuillEditor} from "./QuillEditor";

const chunkArray = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
        arr.slice(i * size, i * size + size)
    );

export const TrueFalseSection = ({subject, onUpdateQuestion, onUpdateAnswer}) => {
    const groupedQuestions = chunkArray(subject.questions, 3);

    return (
        <div className="bg-white mb-8 flex flex-row gap-6">
            {groupedQuestions.map((questionGroup, groupIndex) => (
                <div key={groupIndex} className="mb-8 p-6 border border-2 flex flex-col gap-6 w-1/2">
                    <h3 className="text-xl font-bold font-medium mb-4">
                        Textul {groupIndex + 1}
                    </h3>
                    {questionGroup.map(question => (
                        <div key={question.id} className="mb-4">
                            <QuillEditor
                                value={question.text}
                                onEditorChange={(content) => onUpdateQuestion(question.id, content)}
                                placeholder="Introduceți întrebarea..."
                                className="bg-white  h-48 mb-28"
                            />
                            <div className="flex gap-6">
                                <label className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        checked={question.answer === true}
                                        onChange={() => onUpdateAnswer(question.id, true)}
                                        className="w-4 h-4"
                                    />
                                    <span>Adevărat</span>
                                </label>
                                <label className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        checked={question.answer === false}
                                        onChange={() => onUpdateAnswer(question.id, false)}
                                        className="w-4 h-4"
                                    />
                                    <span>Fals</span>
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
