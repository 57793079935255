import React, { useCallback } from 'react';
import { QuillEditor } from "../assets/QuillEditor";

export const TrueOrFalse = ({ questions, setQuestions }) => {
    const updateQuestionText = useCallback((questionId, content) => {
        // Prevenim update-uri inutile
        const question = questions.find(q => q.question_id === questionId);
        if (question?.questionText === content) {
            return;
        }

        setQuestions(questions.map(q =>
            q.question_id === questionId ? { ...q, questionText: content } : q
        ));
    }, [questions, setQuestions]);

    const updateAnswer = useCallback((questionId, value) => {
        setQuestions(questions.map(q =>
            q.question_id === questionId ? { ...q, correctAnswer: value } : q
        ));
    }, [questions, setQuestions]);

    return (
        <div className="flex flex-col gap-8">
            <h2 className="text-2xl font-semibold mb-4">Întrebări</h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                {questions.map((question, index) => (
                    <div
                        key={`question-${question.question_id}-${index}`}
                        className="border-b border-gray-200 pb-8"
                    >
                        <h3 className="text-xl font-semibold mb-4">
                            Întrebarea {index + 1}
                        </h3>
                        <QuillEditor
                            key={`editor-${question.question_id}`}
                            value={question.questionText}
                            onEditorChange={(content) => updateQuestionText(question.question_id, content)}
                            placeholder={`Introduceți textul pentru întrebarea ${index + 1}`}
                            className="bg-white h-96 mb-20"
                        />
                        <div className="flex gap-6">
                            <label className="flex items-center gap-2">
                                <input
                                    type="radio"
                                    name={`answer-${question.question_id}`}
                                    checked={question.correctAnswer === true}
                                    onChange={() => updateAnswer(question.question_id, true)}
                                    className="w-4 h-4"
                                />
                                <span>Adevărat</span>
                            </label>
                            <label className="flex items-center gap-2">
                                <input
                                    type="radio"
                                    name={`answer-${question.question_id}`}
                                    checked={question.correctAnswer === false}
                                    onChange={() => updateAnswer(question.question_id, false)}
                                    className="w-4 h-4"
                                />
                                <span>Fals</span>
                            </label>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
