import React, {createContext, useContext, useState, useEffect, ReactNode, useMemo} from 'react';
import {useQuery} from '@tanstack/react-query';
import {request} from '../../../Infrastructure';
import {getDeletionRequest} from '../../queries';

interface DeletionRequest {
    entity_id: number;
    customer_id: number;
    customer_email: string;
    test_for_school: number;
    created_at: string;
    scheduled_for: string;
    status: number;
    last_cron_attempt: string | null;
    processed_at: string | null;
}

interface DeletionRequestContextValue {
    deletionRequests: DeletionRequest[];
    loading: boolean;
    error: any;
}

const DeletionRequestContext = createContext<DeletionRequestContextValue | undefined>(undefined);

export const DeletionRequestProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [deletionRequests, setDeletionRequests] = useState<DeletionRequest[]>([]);

    const customerDeletionRequests = useQuery({
        queryKey: ['deletionRequests'],
        queryFn: async () => {
            return request(getDeletionRequest);
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (customerDeletionRequests?.data) {
            setDeletionRequests(customerDeletionRequests?.data?.data?.getDeletionRequest);
        }
    }, [customerDeletionRequests?.data]);

    return (
        <DeletionRequestContext.Provider
            value={{
                deletionRequests,
                loading: customerDeletionRequests.isLoading,
                error: customerDeletionRequests.error,
            }}
        >
            {children}
        </DeletionRequestContext.Provider>
    );
};

export const useDeletionRequests = () => {
    const context = useContext(DeletionRequestContext);
    if (!context) {
        throw new Error('useDeletionRequests must be used within a DeletionRequestProvider');
    }
    return context;
};
