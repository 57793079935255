import React, {useState, useMemo} from 'react';
import {useProductAttributes, useRoGridTestProductProvider} from '../../../../state';
import {TestResult} from "../assets/TestResult";
import {SaveTestBtn, ErrorMessage} from "../../../../../../general";
import {DescriptionSk} from "../../Skeleton/DescriptionSk";
import {Timer} from "../assets/Timer";

function decodeHtml(html: string) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const GridTest = () => {
    const {productSku} = useProductAttributes() || {};

    const {
        gridTestData,
        setTestCompleted,
        saveTestData,
        mutationError,
        clearMutationError,
        isTestCompleted,
        testScore,
        loading,
        error,
    } = useRoGridTestProductProvider();

    const [userAnswers, setUserAnswers] = useState<{ [key: string]: number }>({});
    const [saveMessage, setSaveMessage] = useState<string | null>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const [startTime, setStartTime] = useState<string | null>(null);
    const [endTime, setEndTime] = useState<string | null>(null);

    const decodedTestData = useMemo(() => {
        if (!gridTestData?.gridQuestions) return null;

        const shuffledQuestions = [...gridTestData.gridQuestions].sort(() => Math.random() - 0.5);

        return {
            ...gridTestData,
            gridQuestions: shuffledQuestions.map(question => ({
                ...question,
                questionText: decodeHtml(question.questionText),
                answer1: decodeHtml(question.answer1),
                answer2: decodeHtml(question.answer2),
                answer3: decodeHtml(question.answer3),
                answer4: decodeHtml(question.answer4),
            })),
        };
    }, [gridTestData]);

    const handleAnswerChange = (questionId: number, answerId: number) => {
        setUserAnswers(prev => ({
            ...prev,
            [questionId]: answerId
        }));

        if (errors[questionId]) {
            setErrors(prev => {
                const newErrors = {...prev};
                delete newErrors[questionId];
                return newErrors;
            });
        }
    };

    const handleSave = async () => {
        const newErrors = {};

        decodedTestData.gridQuestions.forEach(question => {
            if (userAnswers[question.question_id] === undefined) {
                newErrors[question.question_id] = 'Selectează un răspuns';
            }
        });

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            setSaveMessage('Te rugăm să răspunzi la toate întrebările înainte de a finaliza testul.');
            return;
        }

        setIsSaving(true);
        clearMutationError();

        try {
            const sortedQuestions = [...decodedTestData.gridQuestions].sort((a, b) => a.question_id - b.question_id);

            const userTestData = sortedQuestions.map(question => ({
                question_id: question.question_id,
                question_text: question.questionText,
                user_answer: userAnswers[question.question_id],
                correct_answer: question.correctAnswer,
                answers: {
                    answer1: question.answer1,
                    answer2: question.answer2,
                    answer3: question.answer3,
                    answer4: question.answer4
                }
            }));

            const testDataToSave = {
                gridTest: userTestData,
                timestamp: new Date().toISOString()
            };

            const result = await saveTestData({
                product_sku: productSku,
                test_type: 'gridTest',
                test_data: JSON.stringify(testDataToSave),
                start_time: startTime,
                end_time: endTime || new Date().toISOString(),
            });

            if (result?.data?.saveCustomerTestData?.success) {
                setSaveMessage('Testul a fost salvat!');
                setTestCompleted(true);
            }

        } catch (error) {
            console.error('Eroare la salvarea răspunsurilor:', error);
            setSaveMessage('A apărut o eroare la salvarea răspunsurilor.');
        } finally {
            setIsSaving(false);
        }
    };

    if (loading) {
        return <DescriptionSk/>;
    }

    if (error) return <p>Eroare la încărcarea testului.</p>;
    if (!decodedTestData?.gridQuestions?.length) return null;

    if (isTestCompleted && testScore?.test_score) {
        return <TestResult score={testScore} saveMessage={saveMessage}/>;
    }

    return (
        <div className="gridTest-container bg-card-bg xl:w-[800px] w-full flex flex-col gap-3 mt-5 mb-10 md:mb-15
            p-3 md:p-6 border-2 border-double border-medium-blue bg-card-bg shadow-round-shadow border-x border-y">
            <div className="flex flex-col items-start justify-start gap-3">
                <Timer
                    maxTime={1200}
                    onStart={(time) => setStartTime(time)}
                    onEnd={(time) => setEndTime(time)}
                />
                <h2 className="uppercase font-font-p tracking-wide font-semibold text-text-color text-2xl">
                    Test grila
                </h2>
                <p className="font-font-p tracking-wide font-semibold text-text-color text-xl">
                    Citeste cu atentie textul/textele de mai jos, pentru a putea raspunde la cerintele formulate:
                </p>
            </div>

            {decodedTestData.gridQuestions.map((question, index) => (
                <div key={question.question_id}
                     className="question w-full p-3 border-2 border-double border-medium-blue border-x border-y flex flex-col gap-3">
                    <p className="font-font-s text-xl font-bold">
                        Cerinta {index + 1}
                    </p>
                    <p className="font-font-s font-normal text-text-color text-xl bg-white p-3"
                       dangerouslySetInnerHTML={{__html: question.questionText}}/>
                    <div className="answers font-font-s font-normal text-text-color text-xl flex flex-col gap-5">
                        {[question.answer1, question.answer2, question.answer3, question.answer4].map((answer, answerIndex) => (
                            <label key={answerIndex}
                                   className="w-full flex flex-row gap-1 items-center justify-start cursor-pointer">
                                <input
                                    type="radio"
                                    className="border-2 border-gray-300 p-2 w:1/12"
                                    name={`question-${question.question_id}`}
                                    checked={userAnswers[question.question_id] === (answerIndex + 1)}
                                    onChange={() => handleAnswerChange(question.question_id, answerIndex + 1)}
                                />
                                <span dangerouslySetInnerHTML={{__html: answer}}
                                      className="font-font-s font-normal text-text-color text-xl w:11/12"/>
                            </label>
                        ))}
                    </div>
                    {errors[question.question_id] && (
                        <p className="error-message text-red-500 font-font-s text-xl">
                            {errors[question.question_id]}
                        </p>
                    )}
                </div>
            ))}

            <div className="flex justify-center items-center flex-col">
                <div className="flex gap-4">
                    <SaveTestBtn
                        onClick={handleSave}
                        disabled={Object.keys(errors).length > 0}
                        isSaving={isSaving}
                    />
                </div>
                {saveMessage && <p className="save-message mt-2 text-olive-color font-font-s text-xl">{saveMessage}</p>}
                {mutationError && <ErrorMessage message={mutationError}/>}
            </div>
        </div>
    );
};
