// AddReviewButton.jsx
import React from 'react';

export const AddReviewButton = () => {
    return (
        <a
            className={'react-css flex flex-row items-center justify-start xl:justify-start font-font-p  tracking-wide text-xl ' +
                'hover:underline gap-2 ' +
                'text-custom-gray focus:text-custom-gray active:text-custom-gray visited:text-custom-gray hover:text-custom-gray'}
            href={'#review_form_react_app'}
            title={'Adaugă o evaluare'}
        >
            <svg
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="peru-color"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="inline-block fill-current text-peru-color cursor-pointer"
            >
                <polygon
                    points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
                />
            </svg>
        </a>
    );
};
