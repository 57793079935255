import React, {createContext, useContext, useState, useEffect, ReactNode} from 'react';
import {useQuery, useMutation, useQueryClient} from "@tanstack/react-query";
import {request} from "../../../Infrastructure";
import {
    getAdminRoGridTestWithQuestions,
    saveRoGridTestWithQuestions
} from '../../queries';

interface GridQuestion {
    question_id: number;
    product_id: number;
    questionText: string;
    answer1: string;
    answer2: string;
    answer3: string;
    answer4: string;
    correctAnswer: number;
}

interface GridTest {
    product_id: number;
    productSku: string;
    status: number;
    createdAt: string;
    updatedAt: string;
}

interface GridTestWithQuestions {
    title: string;
    gridTest: GridTest;
    gridQuestions: GridQuestion[];
}

interface SaveGridTestWithQuestionsInput {
    product_id: number;
    productSku: string;
    status: number;
    questions: GridQuestion[];
}

interface GridTestContextProps {
    gridTestData: GridTestWithQuestions | null;
    loading: boolean;
    error: Error | unknown;
    saveTest: (test: SaveGridTestWithQuestionsInput) => Promise<void>;
}

const GridTestContext = createContext<GridTestContextProps | undefined>(undefined);

export const GridTestProvider: React.FC<{ children: ReactNode, product_id: number }> = ({children, product_id}) => {
    const queryClient = useQueryClient();
    const [gridTestData, setGridTestData] = useState<GridTestWithQuestions | null>(null);

    const gridTestQuery = useQuery({
        queryKey: ['gridTest', product_id],
        queryFn: async () => {
            const response = await request(getAdminRoGridTestWithQuestions, {
                product_id: product_id
            });
            return response.data;
        },
        enabled: !!product_id,
        refetchOnWindowFocus: false,
    });

    const mutation = useMutation({
        mutationFn: async (input: SaveGridTestWithQuestionsInput) => {
            return request(saveRoGridTestWithQuestions, {input});
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['gridTest', product_id]});
        },
        onError: (error) => {
            console.error('Failed to save grid test:', error);
        },
    });

    const saveTest = async (test: SaveGridTestWithQuestionsInput) => {
        await mutation.mutateAsync(test);
    };

    useEffect(() => {
        if (gridTestQuery.data) {
            setGridTestData(gridTestQuery.data.getAdminRoGridTestWithQuestions);
        }
    }, [gridTestQuery.data]);

    return (
        <GridTestContext.Provider value={{
            gridTestData,
            loading: mutation.isLoading || gridTestQuery.isLoading,
            error: mutation.error || gridTestQuery.error,
            saveTest,
        }}>
            {children}
        </GridTestContext.Provider>
    );
};

export const useGridTest = (): GridTestContextProps => {
    const context = useContext(GridTestContext);
    if (!context) {
        throw new Error('useGridTest must be used within a GridTestProvider');
    }
    return context;
};
