import React, {createContext, useContext, useState, useEffect, ReactNode} from 'react';
import {useMutation, useQuery} from "@tanstack/react-query";
import {request} from "../../../Infrastructure";
import {
    getRoTrueFalseTestWithQuestions, saveCustomerTestData,
} from '../../queries';

interface TrueFalseQuestion {
    question_id: number;
    product_id: number;
    questionText: string;
    correctAnswer: boolean;
}

interface TrueFalseTest {
    product_id: number;
    productSku: string;
    status: number;
    createdAt: string;
    updatedAt: string;
}

interface TrueFalseTestWithQuestions {
    status: string;
    trueFalseTest: TrueFalseTest;
    trueFalseQuestions: TrueFalseQuestion[];
}

interface SaveCustomerTestDataInput {
    product_sku: string;
    test_type: string;
    test_data: string;
    start_time: String
    end_time: String
}

interface TestScore {
    test_score: {
        total_questions: number;
        correct_answers: number;
        incorrect_answers: number;
        points_per_question: number;
        total_points: number;
        max_possible_points: number;
        score_percentage: number;
        details: {
            correct_questions: number[];
            incorrect_questions: number[];
        }
    }
}

interface RoTestDataProductContextProps {
    roTrueFalseTestData: TrueFalseTestWithQuestions | null;
    saveTestData: (input: SaveCustomerTestDataInput) => Promise<any>;
    isTestCompleted: boolean;
    setTestCompleted: (completed: boolean) => void;
    testScore: TestScore  | null;
    mutationError: string | null;
    clearMutationError: () => void;
    loading: boolean;
    error: Error | unknown;
}

const RoTestDataProductContext = createContext<RoTestDataProductContextProps | undefined>(undefined);

export const RoTestDataProductProvider: React.FC<{ children: ReactNode, product_id: number }> = ({children, product_id}) => {
    const [roTrueFalseTestData, setRoTrueFalseTestData] = useState<TrueFalseTestWithQuestions | null>(null);
    const [mutationError, setMutationError] = useState<string | null>(null);
    const [isTestCompleted, setIsTestCompleted] = useState(false);
    const [testScore, setTestScore] = useState<TestScore | null>(null);

    const trueOrFalseQuery = useQuery({
        queryKey: ['roTrueFalseTestData', product_id],
        queryFn: async () => {
            const response = await request(getRoTrueFalseTestWithQuestions, { product_id });
            return response.data;
        },
        enabled: !!product_id,
        refetchOnWindowFocus: false,
    });

    const saveTestDataMutation = useMutation({
        mutationFn: (input: SaveCustomerTestDataInput) =>
            request(saveCustomerTestData, { input }),
    });

    const saveTestData = async (input: SaveCustomerTestDataInput) => {
        try {
            if (typeof input.test_data !== 'string') {
                input.test_data = JSON.stringify(input.test_data);
            }
            const result = await saveTestDataMutation.mutateAsync(input);

            if (result.errors && result.errors.length > 0) {
                if (result.errors[0].extensions?.debugMessage?.includes("Tip de test necunoscut")) {
                    setMutationError(null);
                    setIsTestCompleted(true);
                    return { success: true };
                }
                setMutationError(result.errors[0].message);
                return null;
            }

            if (result.data && result.data.saveCustomerTestData) {
                const parsedScore = JSON.parse(result.data.saveCustomerTestData.test_score);

                setMutationError(null);
                setIsTestCompleted(true);
                setTestScore(parsedScore);
                return result.data.saveCustomerTestData;
            }

            setMutationError("Eroare la salvarea datelor testului.");
            return null;
        } catch (error) {
            console.error("Error saving test data:", error);
            setMutationError("A apărut o eroare neașteptată.");
            throw error;
        }
    };

    const setTestCompleted = (completed: boolean) => {
        setIsTestCompleted(completed);
    };

    const clearMutationError = () => setMutationError(null);

    useEffect(() => {
        if (trueOrFalseQuery.data?.getRoTrueFalseTestWithQuestions) {
            setRoTrueFalseTestData(trueOrFalseQuery.data.getRoTrueFalseTestWithQuestions);
        }
    }, [trueOrFalseQuery.data]);

    return (
        <RoTestDataProductContext.Provider value={{
            roTrueFalseTestData,
            saveTestData,
            setTestCompleted,
            isTestCompleted,
            testScore,
            mutationError,
            clearMutationError,
            loading: trueOrFalseQuery.isLoading || saveTestDataMutation.isLoading,
            error: trueOrFalseQuery.error || saveTestDataMutation.error,
        }}>
            {children}
        </RoTestDataProductContext.Provider>
    );
};


export const useRoTestDataProductProvider = (): RoTestDataProductContextProps => {
    const context = useContext(RoTestDataProductContext);
    if (!context) {
        throw new Error('useRoTestDataProductProvider must be used within a RoTestDataProductProvider');
    }
    return context;
};
