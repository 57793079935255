import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const BlockSk = () => {
    return (
        <div className={'w-full h-96 xl:h-[400px] flex flex-col justify-center gap-3 mb-3 md:mb-5'}>
            <Skeleton
                width={'100%'}
                className={'w-full h-96 xl:h-[400px]'}
                baseColor="#f5f5f5"
                highlightColor="#aaa6a0"
            />
        </div>
    );
};
