import React, {useState} from 'react';
import {useCheckoutState} from "../../modules/Checkout";
import {useWindowSize} from '../useWindowSize';
import {LoadingIcon} from './LoadingIcon';
import {Stars} from "../reviews/stars";

declare global {
    interface Window {
        BASE_URL: string;
    }
}

export const CardItem = ({product, isInAddToCartCategory}) => {
    const {isMobile, isTablet} = useWindowSize();
    const {handleAddToCart, addingToCart} = useCheckoutState();
    const [qty, setQty] = useState(1);

    if (!product) return null;

    const formatPrice = (price) => {
        return new Intl.NumberFormat('ro-RO', {style: 'currency', currency: 'RON'}).format(price);
    };

    const regularPriceValue = product?.price_range?.minimum_price?.regular_price?.value;
    const finalPriceValue = product?.price_range?.minimum_price?.final_price?.value;

    const regularPriceFormatted = formatPrice(regularPriceValue);
    const finalPriceFormatted = formatPrice(finalPriceValue);

    const discountValue = regularPriceValue - finalPriceValue;
    const discountValueFormatted = formatPrice(discountValue);

    const reviewCount = product?.reviews?.items?.length;
    const averageStars = product?.reviews?.items?.reduce((acc, review) => acc + review.average_rating, 0) / reviewCount || 0;

    const productName = product?.name || '';
    const cutProductName = productName.length > 70 ? productName.substring(0, 67) + '...' : productName;

    const productUrl = `${window.BASE_URL}${product?.url_rewrites?.[0]?.url || ''}`;

    function decodeHtml(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    const shortDescription = product?.short_description?.html
        ? decodeHtml(product.short_description.html)
        : '';

    return (
        <div className="bg-card-bg relative px-2 py-2.5 justify-between items-center gap-1 shadow hover:shadow-round-shadow
                        p-2.5 px-5 border-2 border-peru-color hover:border-olive-color flex flex-col
                        items-center justify-center w-auto h-auto">
            {discountValue != 0 && (
                <p className="absolute top-[8px] left-[8px] h-[20px] pl-2 pr-2 text-white text-xl
                             font-medium text-center bg-discount flex items-center justify-center font-font-s">
                    {`- ${discountValueFormatted}`}
                </p>
            )}

            <a className="h-[150px] flex justify-center items-center"
               href={productUrl}
               title={product?.name}>
                <img src={product?.customProductImageUrl?.url}
                     alt={product?.small_image?.label}
                     className="w-11/12 object-contain object-center max-h-[150px] text-sm"
                     loading="lazy"/>
            </a>

            <div className="grid grid-row-3 gap-2 h-[210px] xl:h-[230px] w-full">
                <a className="h-[40px] text-text-color hover:no-underline hover:text-text-color
                             active:text-text-color overflow-hidden"
                   href={productUrl}
                   title={product?.name}>
                    <h3 className={'react-css text-xl font-semibold md:font-bold font-font-p  tracking-wide ' +
                        'leading-5 text-black text-center h-[40px] overflow-hidden'}>
                        {cutProductName}
                    </h3>
                </a>

                {shortDescription && (
                    <a className={''}
                       href={productUrl}
                       title={product?.name}>
                        <div
                            className={'text-gray-600 overflow-hidden h-[75px] text-center font-font-s text-xl line-clamp-4'}
                            dangerouslySetInnerHTML={{__html: shortDescription}}
                        />
                    </a>

                )}

                {reviewCount > 0 && (
                    <div className="react-css flex flex-row items-center justify-center gap-1 text-custom-gray">
                        <Stars totalStars={averageStars} starSize="h-6 w-6"/>
                        <p className="flex-row items-center font-bold text-custom-gray focus:text-custom-gray
                                    active:text-custom-gray visited:text-custom-gray text-xl">
                            {reviewCount}
                        </p>
                    </div>
                )}

                <div className={''}>
                    {regularPriceValue === 0 ? (
                        <div></div>
                    ) : (
                        <>
                            {regularPriceValue === finalPriceValue ? (
                                <div
                                    className="react-css flex flex-col items-center gap-0 xl:gap-1 h-[40px] xl:h-[50px] flex flex-col justify-end">
                                    <p className="react-css text-2xl text-left font-bold text-custom-red font-font-p  tracking-wide ml-2">
                                        <span className="react-css">
                                            {finalPriceFormatted}<span className={'text-base'}> {' '}{'cu TVA'}</span>
                                        </span>
                                    </p>
                                </div>
                            ) : (
                                <div
                                    className="react-css flex flex-col items-center gap-0 xl:gap-1 h-[40px] xl:h-[50px] flex flex-col justify-end">
                                    <div className="flex flex-row gap-2 items-start">
                                        <p className="react-css text-xl xl:text-xl font-medium line-through font-font-p  tracking-wide ml-2">
                                            {regularPriceFormatted}
                                        </p>
                                    </div>
                                    <p className="react-css text-2xl font-bold text-red-500 font-font-p  tracking-wide">
                                        <span className="react-css ml-2">
                                            {finalPriceFormatted} <span className={'text-base'}>{' '}{'cu TVA'}</span>
                                        </span>
                                    </p>
                                </div>
                            )}
                        </>
                    )}
                </div>

                <div className="flex justify-center">
                    {!isInAddToCartCategory ? (
                        <button
                            className={'h-[35px] min-w-[100px] font-font-p  tracking-wide text-2xl font-bold p-4 w-auto bg-olive-color ' +
                                'hover:bg-olive-color hover:text-white text-white font-bold py-2 px-4 ' +
                                'focus:bg-olive-color active:olive-color focus:text-white active:text-white ' +
                                'flex items-center transition duration-300 ease-in-out transform hover:scale-105 ' +
                                'border-0 hover:border-0 flex justify-center items-center'}
                            onClick={() => window.location.href = productUrl}
                        >
                            <span className="text-xl font-text-color">
                                {'Afla mai multe'}
                            </span>
                        </button>
                    ) : (
                        <button
                            className={'h-[35px] min-w-[100px] font-font-p  tracking-wide text-2xl font-bold p-4 w-auto bg-olive-color ' +
                                'hover:bg-olive-color hover:text-white text-white font-bold py-2 px-4 ' +
                                'focus:bg-olive-color active:olive-color focus:text-white active:text-white ' +
                                'flex items-center transition duration-300 ease-in-out transform hover:scale-105 ' +
                                'border-0 hover:border-0 flex justify-center items-center'}
                            onClick={() => handleAddToCart({sku: product?.sku, quantity: qty || 1})}
                        >
                            {isMobile || isTablet ? (
                                <>
                                    {product?.sku === addingToCart ? (
                                        <LoadingIcon/>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             height="24"
                                             width="24"
                                             viewBox="0 0 576 512">
                                            <path fill="#F3F4F1"
                                                  d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0
                                                     45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4
                                                     28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24
                                                     24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7
                                                     48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                                        </svg>
                                    )}
                                </>
                            ) : (
                                <>
                                    {product?.sku === addingToCart ? (
                                        <LoadingIcon/>
                                    ) : (
                                        <>
                                            <span className="text-xl font-text-color">
                                                {'Adauga in cos'}
                                            </span>
                                            <svg className="ml-5"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 height="24"
                                                 width="26"
                                                 viewBox="0 0 576 512">
                                                <path fill="#F3F4F1"
                                                      d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0
                                                         45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4
                                                         28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24
                                                         24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7
                                                         48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                                            </svg>
                                        </>
                                    )}
                                </>
                            )}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
