import React, {createContext, useContext, useState, useEffect, ReactNode} from 'react';
import {useQuery, useMutation, useQueryClient} from "@tanstack/react-query";
import {request} from "../../../Infrastructure";
import {
    getAdminRoTrueFalseTestWithQuestions,
    saveTrueFalseTestWithQuestions
} from '../../queries';

interface TrueFalseQuestion {
    question_id: number;
    product_id: number;
    questionText: string;
    correctAnswer: boolean;
}

interface TrueFalseTest {
    product_id: number;
    productSku: string;
    status: number;
    createdAt: string;
    updatedAt: string;
}

interface TrueFalseTestWithQuestions {
    trueFalseTest: TrueFalseTest;
    trueFalseQuestions: TrueFalseQuestion[];
}

interface SaveTrueFalseTestWithQuestionsInput {
    product_id: number;
    productSku: string;
    status: number;
    questions: TrueFalseQuestion[];
}

interface TrueFalseTestContextProps {
    trueFalseTestData: TrueFalseTestWithQuestions | null;
    loading: boolean;
    error: Error | unknown;
    saveTest: (test: SaveTrueFalseTestWithQuestionsInput) => Promise<void>;
}

const TrueFalseTestContext = createContext<TrueFalseTestContextProps | undefined>(undefined);

export const TrueFalseTestProvider: React.FC<{ children: ReactNode, product_id: number }> = ({children, product_id}) => {
    const queryClient = useQueryClient();
    const [trueFalseTestData, setTrueFalseTestData] = useState<TrueFalseTestWithQuestions | null>(null);

    const trueOrFalseQuery = useQuery({
        queryKey: ['trueFalseTest', product_id],
        queryFn: async () => {
            const response = await request(getAdminRoTrueFalseTestWithQuestions, {
                product_id: product_id
            });
            return response.data;
        },
        enabled: !!product_id,
        refetchOnWindowFocus: false,
    });

    const mutation = useMutation({
        mutationFn: async (input: SaveTrueFalseTestWithQuestionsInput) => {
            return request(saveTrueFalseTestWithQuestions, {input});
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['trueFalseTest', product_id]});
        },
        onError: (error) => {
            console.error('Failed to save test:', error);
        },
    });

    const saveTest = async (test: SaveTrueFalseTestWithQuestionsInput) => {
        await mutation.mutateAsync(test);
    };

    useEffect(() => {
        if (trueOrFalseQuery.data) {
            setTrueFalseTestData(trueOrFalseQuery.data.getAdminRoTrueFalseTestWithQuestions);
        }
    }, [trueOrFalseQuery.data]);

    return (
        <TrueFalseTestContext.Provider value={{
            trueFalseTestData,
            loading: mutation.isLoading || trueOrFalseQuery.isLoading,
            error: mutation.error || trueOrFalseQuery.error,
            saveTest,
        }}>
            {children}
        </TrueFalseTestContext.Provider>
    );
};

export const useTrueFalseTest = (): TrueFalseTestContextProps => {
    const context = useContext(TrueFalseTestContext);
    if (!context) {
        throw new Error('useTrueFalseTest must be used within a TrueFalseTestProvider');
    }
    return context;
};
