// GridSection.tsx
import React from 'react';
import {QuillEditor} from "./QuillEditor";

export const GridSection = ({subject, part, updateGridText, updateGridSubject}) => {
    return (
        <div className="bg-white   p-6 shadow-sm mb-4">
            {subject.subpuncte.map(subpunct => (
                <div key={subpunct.id} className="flex flex-col gap-4">
                    <QuillEditor
                        value={subpunct.text}
                        onEditorChange={(content) => updateGridText(part, subject.id, subpunct.id, content)}
                        placeholder="Introduceți textul subiectului..."
                        className="bg-white  h-48 mb-20"
                    />
                    <div className={'flex flex-row gap-2'}>
                        {subpunct.answer.map(ans => (
                            <div key={ans.id} className="flex items-center gap-2">
                                <input
                                    type="checkbox"
                                    checked={ans.checked}
                                    onChange={(e) => updateGridSubject(
                                        part,
                                        subject.id,
                                        subpunct.id,
                                        ans.id,
                                        'checked',
                                        e.target.checked
                                    )}
                                    className="w-4 h-4"
                                />
                                <input
                                    type="text"
                                    value={ans.text}
                                    onChange={(e) => updateGridSubject(
                                        part,
                                        subject.id,
                                        subpunct.id,
                                        ans.id,
                                        'text',
                                        e.target.value
                                    )}
                                    placeholder={`Răspuns ${ans.id}`}
                                    className="flex-1 border  p-2"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};
