import React from "react";
import {DeletionRequestPage} from "./Table/DeletionRequestPage";
import {DeletionRequestProvider} from "../../state";
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";

const queryClient = client();

export const AdminCustomerDeleteRequest = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <DeletionRequestProvider>
                <DeletionRequestPage/>
            </DeletionRequestProvider>
        </QueryClientProvider>
    )
}
