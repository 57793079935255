import React from 'react';

interface SaveTestBtnProps {
    onClick: () => void;
    disabled: boolean;
    isSaving: boolean;
}

export const SaveTestBtn: React.FC<SaveTestBtnProps> = ({ onClick, disabled, isSaving }) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled || isSaving}
            className={'bg-olive-color hover:bg-olive-color hover:text-white text-white font-bold py-2 px-4 ' +
                'focus:bg-olive-color active:olive-color focus:text-white active:text-white ' +
                'flex items-center transition duration-300 ease-in-out transform hover:scale-105'}
        >
            {isSaving ? 'Se salvează...' : 'Finalizează testul'}
        </button>
    );
};
