import React, { useState, useEffect } from 'react';
import { Lock, Unlock } from 'lucide-react';

declare global {
    interface Window {
        BASE_URL: string;
    }
}

export const AnimatedLoginPrompt = () => {
    const [isLocked, setIsLocked] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsLocked(prev => !prev);
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    const loginUrl = `${window.BASE_URL}customer/account/login`;
    const createAccountUrl = `${window.BASE_URL}customer/account/create`;
    const testHistory = `${window.BASE_URL}customer_account/tests/history/`;

    return (
        <div className="authentification w-full flex flex-col items-center justify-center">
            <div
                className="bg-card-bg shadow-round-shadow flex flex-col items-center justify-center p-8 w-full md:w-1/2">
                <div className="mb-6 relative">
                    {isLocked ? (
                        <Lock className="w-16 h-16 text-red-500 animate-bounce"/>
                    ) : (
                        <Unlock className="w-16 h-16 text-olive-color animate-pulse"/>
                    )}
                </div>
                <h3 className="text-2xl font-bold font-font-p  tracking-wide mb-4 text-text-color">
                    Autentificare necesară
                </h3>
                <p className="text-xl font-font-s text-center text-text-color mb-3">
                    pentru a accesa conținutul sau pentru a salva progresul testelor.
                </p>
                <p className="text-xl font-font-s text-center text-text-color mb-3">
                    {'Vei avea acces și la'} {' '}
                    <a href={testHistory} className="text-xl font-font-s font-semibold  text-center text-peru-color mb-3 no-underline hover:underline hover:text-text-color">
                        {'istoricul testelor'} {', '}
                    </a>
                    {'inclusiv rezultatele acestora.'}
                </p>
                <a href={loginUrl} className="no-underline hover:no-underline">
                    <button
                        className={'bg-olive-color hover:bg-olive-color hover:text-white text-white font-bold py-2 px-4 ' +
                            'focus:bg-olive-color active:olive-color focus:text-white active:text-white ' +
                            'flex items-center transition duration-300 ease-in-out transform hover:scale-105'}>
                        Autentificare
                    </button>
                </a>
                <p className="text-xl font-font-s text-center text-text-color mb-3 mt-6">
                    Sau daca nu ai un cont, te rugăm să îți creezi unul.
                </p>
                <a href={createAccountUrl} className="no-underline hover:no-underline">
                    <button
                        className="bg-white border-2 border-olive-color text-olive-color hover:text-white
                                hover:bg-olive-color font-bold py-2 px-6 flex items-center
                                focus:bg-olive-color active:olive-color focus:text-white active:text-white
                                transition duration-300 ease-in-out transform hover:scale-105">
                        Creează cont
                    </button>
                </a>
            </div>
        </div>
    );
};
