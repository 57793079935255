import React, {createContext, useContext, useState, useEffect, ReactNode} from 'react';
import {useMutation, useQuery} from "@tanstack/react-query";
import {request} from "../../../Infrastructure";
import {
    getRoGridTestWithQuestions,
    saveCustomerTestData,
} from '../../queries';

interface GridQuestion {
    question_id: number;
    product_id: number;
    questionText: string;
    answer1: string;
    answer2: string;
    answer3: string;
    answer4: string;
    correctAnswer: number;
}

interface GridTest {
    product_id: number;
    productSku: string;
    status: number;
    createdAt: string;
    updatedAt: string;
}

interface GridTestWithQuestions {
    status: string;
    title: string;
    gridTest: GridTest;
    gridQuestions: GridQuestion[];
}

interface SaveCustomerTestDataInput {
    product_sku: string;
    test_type: string;
    test_data: string;
    start_time: String;
    end_time: String;
}

interface TestScore {
    test_score: {
        total_questions: number;
        correct_answers: number;
        incorrect_answers: number;
        points_per_question: number;
        total_points: number;
        max_possible_points: number;
        score_percentage: number;
        details: {
            correct_questions: number[];
            incorrect_questions: number[];
        }
    }
}

interface RoGridTestProductContextProps {
    gridTestData: GridTestWithQuestions | null;
    saveTestData: (input: SaveCustomerTestDataInput) => Promise<any>;
    isTestCompleted: boolean;
    setTestCompleted: (completed: boolean) => void;
    testScore: TestScore | null;
    mutationError: string | null;
    clearMutationError: () => void;
    loading: boolean;
    error: Error | unknown;
}

const RoGridTestProductContext = createContext<RoGridTestProductContextProps | undefined>(undefined);

export const RoGridTestProductProvider: React.FC<{ children: ReactNode, product_id: number }> = ({children, product_id}) => {
    const [gridTestData, setGridTestData] = useState<GridTestWithQuestions | null>(null);
    const [mutationError, setMutationError] = useState<string | null>(null);
    const [isTestCompleted, setIsTestCompleted] = useState(false);
    const [testScore, setTestScore] = useState<TestScore | null>(null);

    const gridTestQuery = useQuery({
        queryKey: ['roGridTestData', product_id],
        queryFn: async () => {
            const response = await request(getRoGridTestWithQuestions, { product_id });
            return response.data;
        },
        enabled: !!product_id,
        refetchOnWindowFocus: false,
    });

    const saveTestDataMutation = useMutation({
        mutationFn: (input: SaveCustomerTestDataInput) =>
            request(saveCustomerTestData, { input }),
    });

    const saveTestData = async (input: SaveCustomerTestDataInput) => {
        try {
            if (typeof input.test_data !== 'string') {
                input.test_data = JSON.stringify(input.test_data);
            }
            const result = await saveTestDataMutation.mutateAsync(input);

            if (result.errors && result.errors.length > 0) {
                if (result.errors[0].extensions?.debugMessage?.includes("Tip de test necunoscut")) {
                    setMutationError(null);
                    setIsTestCompleted(true);
                    return { success: true };
                }
                setMutationError(result.errors[0].message);
                return null;
            }

            if (result.data && result.data.saveCustomerTestData) {
                const parsedScore = JSON.parse(result.data.saveCustomerTestData.test_score);

                setMutationError(null);
                setIsTestCompleted(true);
                setTestScore(parsedScore);
                return result.data.saveCustomerTestData;
            }

            setMutationError("Eroare la salvarea datelor testului.");
            return null;
        } catch (error) {
            console.error("Error saving test data:", error);
            setMutationError("A apărut o eroare neașteptată.");
            throw error;
        }
    };

    const setTestCompleted = (completed: boolean) => {
        setIsTestCompleted(completed);
    };

    const clearMutationError = () => setMutationError(null);

    useEffect(() => {
        if (gridTestQuery.data?.getRoGridTestWithQuestions) {
            setGridTestData(gridTestQuery.data.getRoGridTestWithQuestions);
        }
    }, [gridTestQuery.data]);

    return (
        <RoGridTestProductContext.Provider value={{
            gridTestData,
            saveTestData,
            setTestCompleted,
            isTestCompleted,
            testScore,
            mutationError,
            clearMutationError,
            loading: gridTestQuery.isLoading || saveTestDataMutation.isLoading,
            error: gridTestQuery.error || saveTestDataMutation.error,
        }}>
            {children}
        </RoGridTestProductContext.Provider>
    );
};

export const useRoGridTestProductProvider = (): RoGridTestProductContextProps => {
    const context = useContext(RoGridTestProductContext);
    if (!context) {
        throw new Error('useRoGridTestProductProvider must be used within a RoGridTestProductProvider');
    }
    return context;
};
