import React from 'react';
import { useTable } from 'react-table';

export const DeletionRequestTable = ({ data }) => {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Entity ID',
                accessor: 'entity_id',
            },
            {
                Header: 'Customer ID',
                accessor: 'customer_id',
            },
            {
                Header: 'Customer Email',
                accessor: 'customer_email',
            },
            {
                Header: 'Created At',
                accessor: 'created_at',
            },
            {
                Header: 'Scheduled For',
                accessor: 'scheduled_for',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Last Cron Attempt',
                accessor: 'last_cron_attempt',
            },
            {
                Header: 'Processed At',
                accessor: 'processed_at',
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <table {...getTableProps()}
               style={{ border: 'solid 1px blue' }}
               className={'w-full'}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th
                            {...column.getHeaderProps()}
                            style={{
                                borderBottom: 'solid 3px red',
                                background: 'aliceblue',
                                color: 'black',
                                fontWeight: 'bold',
                            }}
                            className={'border by-gray-200'}
                        >
                            {column.render('Header')}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map(row => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                            <td
                                {...cell.getCellProps()}
                                style={{
                                    padding: '10px',
                                    border: 'solid 1px gray',
                                    background: 'papayawhip',
                                }}
                            >
                                {cell.render('Cell')}
                            </td>
                        ))}
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};
