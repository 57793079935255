import React, { useState, useEffect } from 'react';
import {Play, Square, TimerIcon} from "lucide-react";

export const Timer = ({ maxTime, onStart, onEnd }) => {
    const [elapsedTime, setElapsedTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [formattedTime, setFormattedTime] = useState('00:00');

    useEffect(() => {
        let timer;
        if (isRunning) {
            timer = setInterval(() => {
                setElapsedTime(prev => {
                    const newElapsed = prev + 1;
                    const minutes = Math.floor(newElapsed / 60).toString().padStart(2, '0');
                    const seconds = (newElapsed % 60).toString().padStart(2, '0');
                    setFormattedTime(`${minutes}:${seconds}`);

                    if (maxTime && newElapsed >= maxTime) {
                        handleStop();
                    }

                    return newElapsed;
                });
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [isRunning, maxTime]);

    const handleStart = () => {
        setIsRunning(true);
        const startTime = new Date().toISOString();
        if (onStart) onStart(startTime);
    };

    const handleStop = () => {
        setIsRunning(false);
        const endTime = new Date().toISOString();
        if (onEnd) onEnd(endTime);
    };

    const progress = maxTime ? (elapsedTime / maxTime) * 100 : 0;

    return (
        <div className="w-full max-w-md mx-auto bg-card-bg rounded-xl shadow-round-shadow p-6 space-y-6 mb-5 xl:absolute xl:right-[15px] xl:top-[15px] transition-all duration-300 ease-in-out">
            <div className="flex items-center justify-center space-x-3">
                <TimerIcon className="w-6 h-6 text-olive-color" />
                <h2 className="text-2xl font-font-s font-semibold text-custom-black">Cronometru Test</h2>
            </div>

            <div className="relative pt-4">
                <div className="text-center mb-2">
                    <span className="text-8xl font-bold font-font-s text-wood-color tracking-wider">
                        {formattedTime}
                    </span>
                </div>

                {maxTime && (
                    <div className="w-full bg-gray-200 rounded-full h-2.5 mt-4">
                        <div
                            className="bg-peru-color h-2.5 rounded-full transition-all duration-500"
                            style={{ width: `${progress}%` }}
                        />
                    </div>
                )}
            </div>

            <div className="text-center text-sub-text font-font-p">
                <p>Timp maxim: {maxTime ? `${Math.floor(maxTime / 60)}:${(maxTime % 60).toString().padStart(2, '0')}` : 'Nespecificat'}</p>
            </div>

            <div className="flex justify-center pt-2">
                {!isRunning ? (
                    <button
                        onClick={handleStart}
                        className="flex items-center space-x-2 bg-olive-color hover:bg-opacity-90
                        text-white px-6 py-2 rounded-lg transition-all duration-300 shadow-md hover:shadow-lg"
                    >
                        <Play className="w-5 h-5" />
                        <span>Pornește</span>
                    </button>
                ) : (
                    <button
                        onClick={handleStop}
                        className="flex items-center space-x-2 bg-wood-color hover:bg-opacity-90
                        text-white px-6 py-2 rounded-lg transition-all duration-300 shadow-md hover:shadow-lg"
                    >
                        <Square className="w-5 h-5" />
                        <span>Oprește</span>
                    </button>
                )}
            </div>
        </div>
    );
};

