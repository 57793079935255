import React from 'react';
import {StatusIcon} from "../../../../../../../general";

export const GridEvaluationView = ({testData}) => {
    return (
        <div className="flex flex-col gap-3 mt-5 mb-10 p-3 md:p-6">
            <h2 className="uppercase font-semibold text-gray-700 text-2xl">
                Întrebări Grilă - Rezultate
            </h2>
            {testData.gridTestData.map((item) => (
                <div key={item.id} className="flex flex-col gap-5">
                    {item.subpuncte.map((subpunct) => (
                        <div key={subpunct.id} className="p-3 border">
                            <p className="text-xl  font-bold">Întrebarea {subpunct.id}</p>
                            <div
                                dangerouslySetInnerHTML={{__html: subpunct.text}}
                                className="text-xl bg-white p-3 mb-4"
                            />
                            <div className="flex flex-row justify-between items-center">
                                <div>
                                    <p className="text-xl font-bold">Răspuns ales:</p>
                                    <p className="text-xl">
                                        {subpunct.right_answer.find(a => a.id === subpunct.userAnswer)?.text || 'Niciun răspuns'}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-xl font-bold">Răspuns corect:</p>
                                    <p className="text-xl">
                                        {subpunct.right_answer.find(a => a.id === subpunct.correctAnswerId)?.text}
                                    </p>
                                </div>
                                <div className="flex items-center gap-2">
                                    <StatusIcon
                                        isCorrect={subpunct.userAnswer === subpunct.correctAnswerId}
                                        size="lg"
                                    />
                                    <span>{subpunct.score} puncte</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
