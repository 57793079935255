import React from 'react';
import {useProductAttributes, useRoGridTestProductProvider, useRoTestDataProductProvider} from '../../../state';
import {GridTest} from "./testTypes/GridTest";
import {TrueOrFalse} from "./testTypes/TrueOrFalse";
import {MultipleAnswer} from "./testTypes/MultipleAnswer";
import {AnimatedLoginPrompt} from "./assets/AnimatedLoginPrompt";
import {SubscriptionAccessDenied} from "./assets/SubscriptionAccessDenied";
import {DescriptionSk} from "../Skeleton/DescriptionSk";
import {NationalEvaluation} from "./testTypes/NationalEvaluation";

export const TestComponents = () => {
    const {testData, loading, isInTestCategory} = useProductAttributes();
    const {roTrueFalseTestData} = useRoTestDataProductProvider();
    const {gridTestData} = useRoGridTestProductProvider();

    if (loading) {
        return <DescriptionSk/>;
    }

    if (!isInTestCategory) {
        return null;
    }

    const renderTestComponent = (status: string, Component: React.ComponentType) => {
        switch (status) {
            case 'userNotAuthenticated':
                return <AnimatedLoginPrompt/>;
            case 'noSubscriptionAccess':
                return <SubscriptionAccessDenied/>;
            case 'success':
                return <Component/>;
            default:
                return null;
        }
    };

    return (
        <>
            {roTrueFalseTestData && renderTestComponent(roTrueFalseTestData.status, TrueOrFalse)}
            {gridTestData && renderTestComponent(gridTestData.status, GridTest)}
        </>
    );
}

            {/*/!* Gestionare pentru categoria de teste *!/*/}
            {/*{isInTestCategory && testData ? (*/}
            {/*    testData === 'userNotAuthenticated' ? (*/}
            {/*        <AnimatedLoginPrompt />*/}
            {/*    ) : testData === 'noSubscriptionAccess' ? (*/}
            {/*        <SubscriptionAccessDenied />*/}
            {/*    ) : (*/}
            {/*        <>*/}
            {/*            <GridTest />*/}
            {/*            <MultipleAnswer />*/}
            {/*            <TrueOrFalse />*/}
            {/*            <NationalEvaluation />*/}
            {/*        </>*/}
            {/*    )*/}
            {/*) : null}*/}
