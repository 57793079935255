import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const PriceInfoSk = () => {

    return (
        <div className={'w-full xl:w-1/2 h-16 flex flex-col justify-center xl:justify-start gap-3'}>
            <Skeleton
                width={'100%'}
                className={'w-full xl:w-1/2 h-16'}
                baseColor="#f5f5f5"
                highlightColor="#aaa6a0"
            />
        </div>
    );
}
