import React from 'react';
import {StatusIcon} from "../../../../../../../general";

export const MultiAnswerEvaluationView = ({testData}) => {
    return (
        <div className="flex flex-col gap-3 mt-5 mb-10 p-3 md:p-6">
            <h2 className="uppercase font-semibold text-gray-700 text-2xl">
                Răspunsuri Multiple - Rezultate
            </h2>
            {testData?.multipleAnswer.map((item) => (
                <div key={item.id} className="flex flex-col gap-5">
                    {item.subpuncte.map((subpunct) => (
                        <div key={subpunct.id} className="p-3 border">
                            <p className="text-xl  font-bold">Întrebarea {subpunct.id}</p>
                            <div
                                dangerouslySetInnerHTML={{__html: subpunct.text}}
                                className="text-xl bg-white p-3"
                            />
                            {subpunct.answers.map((answer) => (
                                <div key={answer.id} className="mt-2 flex flex-row justify-between items-center">
                                    <div>
                                        <p className="text-xl font-bold">Răspuns elev:</p>
                                        <p className="text-xl">{answer.userAnswer}</p>
                                    </div>
                                    <div>
                                        <p className="text-xl font-bold">Răspunsuri corecte:</p>
                                        <p className="text-xl">{answer.right_answer.join(', ')}</p>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <StatusIcon
                                            isCorrect={answer.score > 0}
                                            size="lg"
                                        />
                                        <span>{answer.score} puncte</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
