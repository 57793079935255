import React, {useEffect, useState} from 'react';
import {useEvaluationState} from "../../../../state";
import {QuillEditor} from "../assets/QuillEditor";
import {TrueFalseSection} from "../assets/TrueFalseSection";
import {MultiAnswerSection} from "../assets/MultiAnswerSection";
import {GridSection} from "../assets/GridSection";

interface InitialText {
    id: number;
    text: string;
}

interface Answer {
    id: number;
    words: string[];
}

interface GridAnswer {
    id: number;
    text: string;
    checked: boolean;
}

interface multiAnswersSubject {
    id: number;
    subpuncte: {
        id: number;
        text: string;
        answers: [Answer, Answer];
    }[];
}

interface GridSubject {
    id: number;
    subpuncte: {
        id: number;
        text: string;
        answer: GridAnswer[];
    }[];
}

interface TrueFalseQuestion {
    id: number;
    text: string;
    answer: boolean;
}

interface TrueFalseSubject {
    id: number;
    questions: TrueFalseQuestion[];
}

interface RequirementSubject {
    id: number;
    text: string;
}

// Definim structura pentru partea A
interface PartA {
    multiAnswers: multiAnswersSubject[];
    gridSubjects: GridSubject[];
    trueFalseSubjects: TrueFalseSubject[];
    requirementSubjects: RequirementSubject[];
}

// Definim structura pentru partea B
interface PartB {
    gridSubjects: GridSubject[];
    multiAnswers: multiAnswersSubject[];
    requirementSubjects: RequirementSubject[];
}

interface NationalEvaluationContent {
    initialTexts: {
        id: number;
        texts: InitialText[];
    }[];
    partA: PartA;
    partB: PartB;
    requirementSubjects: RequirementSubject[];
}

interface NationalEvaluationData {
    product_id: number;
    product_sku?: string;
    nationalEvaluation: NationalEvaluationContent[];
}

const createInitialData = (productId: number): NationalEvaluationData => ({
    product_id: productId,
    nationalEvaluation: [{
        initialTexts: [{
            id: 1,
            texts: [
                {id: 1, text: ''},
                {id: 2, text: ''}
            ]
        }],
        partA: {
            multiAnswers:Array(1).fill(null).map((_, index) => ({
                id: index + 1,
                subpuncte: Array(1).fill(null).map((_, index) => ({
                    id: index + 1,
                    text: '',
                    answers: [
                        {id: 1, words: []},
                        {id: 2, words: []}
                    ]
                }))
            })),
            gridSubjects: Array(3).fill(null).map((_, index) => ({
                id: index + 1,  // Adăugăm explicit ID-ul subiectului
                subpuncte: [{
                    id: index + 1,  // ID unic pentru subpunct
                    text: '',
                    answer: Array(4).fill(null).map((_, idx) => ({
                        id: idx + 1,
                        text: '',
                        checked: false
                    }))
                }]
            })),
            trueFalseSubjects: [{
                id: 1,
                questions: Array(6).fill(null).map((_, index) => ({
                    id: index + 1,
                    text: '',
                    answer: null
                }))
            }],
            requirementSubjects: Array(4).fill(null).map((_, index) => ({
                id: index + 1,
                text: ''
            }))
        },
        partB: {
            gridSubjects: Array(4).fill(null).map((_, index) => ({
                id: index + 1,  // Adăugăm explicit ID-ul subiectului
                subpuncte: [{
                    id: index + 1,  // ID unic pentru subpunct
                    text: '',
                    answer: Array(4).fill(null).map((_, idx) => ({
                        id: idx + 1,
                        text: '',
                        checked: false
                    }))
                }]
            })),
            multiAnswers:Array(1).fill(null).map((_, index) => ({
                id: index + 1,
                subpuncte: Array(1).fill(null).map((_, index) => ({
                    id: index + 1,
                    text: '',
                    answers: [
                        {id: 1, words: []},
                        {id: 2, words: []}
                    ]
                }))
            })),
            requirementSubjects: Array(3).fill(null).map((_, index) => ({
                id: index + 1,
                text: ''
            }))
        },
        requirementSubjects: Array(1).fill(null).map((_, index) => ({
            id: index + 1,
            text: ''
        }))
    }]
});

export const NationalEvaluation: React.FC<{ productId: number }> = ({productId}) => {
    const {evaluationData, handleSaveEvaluation, loading, error} = useEvaluationState();
    const [localData, setLocalData] = useState<NationalEvaluationData>(() => createInitialData(productId));

    useEffect(() => {
        if (evaluationData?.nationalEvaluation) {
            setLocalData(evaluationData);
        } else if (evaluationData?.product_id) {
            setLocalData(createInitialData(evaluationData.product_id));
        }
    }, [evaluationData]);

    // Generic function types for type safety
    type PartType = 'partA' | 'partB';
    type UpdateFieldType = 'text' | 'checked';

// Generic update function for text fields
    const updateGenericField = <T extends keyof NationalEvaluationData>(
        path: T,
        updateFn: (prevData: NationalEvaluationData[T]) => NationalEvaluationData[T]
    ) => {
        setLocalData(prev => ({
            ...prev,
            [path]: updateFn(prev[path])
        }));
    };

// Initial texts update
    const updateText = (textId: number, content: string) => {
        setLocalData(prev => ({
            ...prev,
            nationalEvaluation: prev.nationalEvaluation.map(data => ({
                ...data,
                initialTexts: data.initialTexts.map(textBlock => ({
                    ...textBlock,
                    texts: textBlock.texts.map(text =>
                        text.id === textId ? {...text, text: content} : text
                    )
                }))
            }))
        }));
    };

// Multiple Answers updates
    const updateMultiAnswer = (part: 'partA' | 'partB', questionId: number, answerId: number, value: string) => {
        setLocalData(prev => ({
            ...prev,
            nationalEvaluation: prev.nationalEvaluation.map(data => ({
                ...data,
                [part]: {
                    ...data[part],
                    multiAnswers: data[part].multiAnswers.map(subject => ({
                        ...subject,
                        subpuncte: subject.subpuncte.map(sp =>
                            sp.id === questionId ? {
                                ...sp,
                                answers: sp.answers.map(ans =>
                                    ans.id === answerId
                                        ? { ...ans, words: value ? [value] : [] }
                                        : ans
                                ) as [Answer, Answer]
                            } : sp
                        )
                    }))
                }
            }))
        }));
    };

    const updateMultiAnswerText = (part: 'partA' | 'partB', questionId: number, content: string) => {
        setLocalData(prev => ({
            ...prev,
            nationalEvaluation: prev.nationalEvaluation.map(data => ({
                ...data,
                [part]: {
                    ...data[part],
                    multiAnswers: data[part].multiAnswers.map(subject => ({
                        ...subject,
                        subpuncte: subject.subpuncte.map(sp =>
                            sp.id === questionId ? { ...sp, text: content } : sp
                        )
                    }))
                }
            }))
        }));
    };

// Grid updates
    const updateGridText = (part: string, subjectId: number, subpunctId: number, content: string) => {
        setLocalData(prev => ({
            ...prev,
            nationalEvaluation: prev.nationalEvaluation.map(data => ({
                ...data,
                [part]: {
                    ...data[part],
                    gridSubjects: data[part].gridSubjects.map(subject =>
                        subject.id === subjectId ? {
                            ...subject,
                            subpuncte: subject.subpuncte.map(sp =>
                                sp.id === subpunctId ? { ...sp, text: content } : sp
                            )
                        } : subject
                    )
                }
            }))
        }));
    };

    const updateGridSubject = (
        part: string,
        subjectId: number,
        subpunctId: number,
        answerId: number,
        field: 'text' | 'checked',
        value: string | boolean
    ) => {
        setLocalData(prev => ({
            ...prev,
            nationalEvaluation: prev.nationalEvaluation.map(data => ({
                ...data,
                [part]: {
                    ...data[part],
                    gridSubjects: data[part].gridSubjects.map(subject =>
                        subject.id === subjectId ? {
                            ...subject,
                            subpuncte: subject.subpuncte.map(sp =>
                                sp.id === subpunctId ? {
                                    ...sp,
                                    answer: sp.answer.map(ans =>
                                        ans.id === answerId ? { ...ans, [field]: value } : ans
                                    )
                                } : sp
                            )
                        } : subject
                    )
                }
            }))
        }));
    };


// True/False updates (only in partA)
    const updateTrueFalseQuestion = (questionId: number, content: string) => {
        setLocalData(prev => ({
            ...prev,
            nationalEvaluation: prev.nationalEvaluation.map(data => ({
                ...data,
                partA: {
                    ...data.partA,
                    trueFalseSubjects: data.partA.trueFalseSubjects.map(subject => ({
                        ...subject,
                        questions: subject.questions.map(q =>
                            q.id === questionId ? {...q, text: content} : q
                        )
                    }))
                }
            }))
        }));
    };

    const updateTrueFalseAnswer = (questionId: number, answer: boolean) => {
        setLocalData(prev => ({
            ...prev,
            nationalEvaluation: prev.nationalEvaluation.map(data => ({
                ...data,
                partA: {
                    ...data.partA,
                    trueFalseSubjects: data.partA.trueFalseSubjects.map(subject => ({
                        ...subject,
                        questions: subject.questions.map(q =>
                            q.id === questionId ? {...q, answer} : q
                        )
                    }))
                }
            }))
        }));
    };

// Requirements updates
    const updateRequirement = (
        part: 'partA' | 'partB' | 'general',
        requirementId: number,
        content: string
    ) => {
        setLocalData(prev => ({
            ...prev,
            nationalEvaluation: prev.nationalEvaluation.map(data => ({
                ...data,
                // Pentru `general` requirementSubjects
                ...(part === 'general' && {
                    requirementSubjects: data.requirementSubjects.map(req =>
                        req.id === requirementId ? {...req, text: content} : req
                    )
                }),
                // Pentru `partA` requirementSubjects
                ...(part === 'partA' && {
                    partA: {
                        ...data.partA,
                        requirementSubjects: data.partA.requirementSubjects.map(req =>
                            req.id === requirementId ? {...req, text: content} : req
                        )
                    }
                }),
                // Pentru `partB` requirementSubjects
                ...(part === 'partB' && {
                    partB: {
                        ...data.partB,
                        requirementSubjects: data.partB.requirementSubjects.map(req =>
                            req.id === requirementId ? {...req, text: content} : req
                        )
                    }
                })
            }))
        }));
    };

    const handleSave = () => {
        if (localData) {
            handleSaveEvaluation(localData, () => {
                window.location.reload();
            });
        }
    };

    if (loading) return <div>Se încarcă...</div>;
    if (error) return <div>Eroare: {error instanceof Error ? error.message : 'Eroare necunoscută'}</div>;

    const currentTexts = localData.nationalEvaluation[0].initialTexts[0];
    const chunkArray = (arr, size) =>
        Array.from({length: Math.ceil(arr.length / size)}, (_, i) =>
            arr.slice(i * size, i * size + size)
        );

    return (
        <div className="w-full">
            <h1 className="text-4xl font-bold text-amber-800 mb-8">
                Evaluare Națională
            </h1>
            <h2 className="text-3xl font-bold text-amber-800 mb-6">Subiectul I</h2>
            {/* Initial Texts Section */}
            <section className="mb-12">
                <div className="flex gap-4 mb-4 flex flex-col items-center justify-center">
                    {currentTexts.texts.map((text) => (
                        <div key={text.id} className="bg-white mb-20">
                            <h2 className="text-2xl font-semibold mb-4">Text {text.id}</h2>
                            <QuillEditor
                                value={text.text}
                                onEditorChange={(newValue) => updateText(text.id, newValue)}
                                placeholder={`Introduceți textul ${text.id}`}
                                className="bg-white h-96"
                            />
                        </div>
                    ))}
                </div>
            </section>

            {/* Part A Section */}
            <section className="mb-12">
                <h2 className="text-3xl font-bold text-amber-800 mb-6">Partea A</h2>

                {/* Multi Answers */}
                <div className="mb-8">
                    <h3 className="text-2xl font-semibold mb-4">1</h3>
                    {localData.nationalEvaluation[0].partA.multiAnswers.map(subject => (
                        <MultiAnswerSection
                            key={subject.id}
                            subject={subject}
                            part="partA"
                            onUpdateText={updateMultiAnswerText}
                            onUpdateAnswer={updateMultiAnswer}
                        />
                    ))}
                </div>

                {/* Grid Subjects */}
                {localData.nationalEvaluation[0].partA.gridSubjects.map((subject, index) => (
                    <div key={subject.id} className="mb-8">
                        <h3 className="text-2xl font-semibold mb-4">{index + 2}</h3>
                        <GridSection
                            subject={subject}
                            part="partA"
                            updateGridText={updateGridText}
                            updateGridSubject={updateGridSubject}
                        />
                    </div>
                ))}

                {/* True/False Subjects */}
                {localData.nationalEvaluation[0].partA.trueFalseSubjects.map((subject, index) => (
                    <div key={subject.id} className="mb-8">
                        <h3 className="text-2xl font-semibold mb-4">{index + 5}</h3>
                        <TrueFalseSection
                            subject={subject}
                            onUpdateQuestion={updateTrueFalseQuestion}
                            onUpdateAnswer={updateTrueFalseAnswer}
                        />
                    </div>
                ))}
                {localData.nationalEvaluation[0].partA.requirementSubjects.map((req, index) => (
                    <div key={req.id} className="bg-white p-6 shadow-sm mb-4">
                        <h4 className="text-xl font-semibold mb-4">
                            {index + 6} {/* Numerotare de la 6 */}
                        </h4>
                        <QuillEditor
                            value={req.text}
                            onEditorChange={(content) => updateRequirement('partA', req.id, content)}
                            placeholder="Introduceți cerința ..."
                            className="bg-white "
                        />
                    </div>
                ))}
            </section>

            {/* Part B Section */}
            <section className="mb-12">
                <h2 className="text-3xl font-bold text-amber-800 mb-6">Partea B</h2>

                {/* Grid Subjects for Part B */}
                {localData.nationalEvaluation[0].partB.gridSubjects.map((subject, index) => (
                    <div key={subject.id} className="mb-8">
                        <h3 className="text-2xl font-semibold mb-4">{index + 1}</h3>
                        <GridSection
                            subject={subject}
                            part="partB"
                            updateGridText={updateGridText}
                            updateGridSubject={updateGridSubject}
                        />
                    </div>
                ))}

                {/* Multi Answers for Part B */}
                {localData.nationalEvaluation[0].partB.multiAnswers.map((subject, index) => (
                    <div>
                        <h3 className="text-2xl font-semibold mb-4">{index + 5}</h3>
                        <div key={subject.id} className="mb-8">
                            <MultiAnswerSection
                                subject={subject}
                                part="partB"
                                onUpdateText={updateMultiAnswerText}
                                onUpdateAnswer={updateMultiAnswer}
                            />
                        </div>
                    </div>
                ))}

                {localData.nationalEvaluation[0].partB.requirementSubjects.map((req, index) => (
                    <div key={req.id} className="bg-white p-6 shadow-sm mb-4">
                        <h4 className="text-xl font-semibold mb-4">
                            {index + 6}
                        </h4>
                        <QuillEditor
                            value={req.text}
                            onEditorChange={(content) => updateRequirement('partB', req.id, content)}
                            placeholder="Introduceți cerința ..."
                            className="bg-white "
                        />
                    </div>
                ))}
            </section>
            {/* General Requirements Section */}
            <section className="mb-8">
                <h2 className="text-3xl font-bold text-amber-800 mb-6">Subiectul al II-lea</h2>
                {localData.nationalEvaluation[0].requirementSubjects.map(req => (
                    <div key={req.id} className="bg-white p-6 shadow-sm mb-4">
                        <QuillEditor
                            value={req.text}
                            onEditorChange={(content) => updateRequirement('general', req.id, content)}
                            placeholder="Introduceți cerința..."
                            className="bg-white "
                        />
                    </div>
                ))}
            </section>

            {/* Save Button */}
            <div className="flex justify-end mt-8">
                <button
                    onClick={handleSave}
                    className="px-6 py-3 bg-amber-800 text-white hover:bg-amber-700 transition-colors"
                >
                    Salvează testul
                </button>
            </div>
        </div>
    );
};
