import React, {useState} from 'react';
import {useThemeContextProvider} from '../../../state';

export const NewsLetterForm: React.FC = () => {
    const [email, setEmail] = useState('');
    const {subscribeNewsletter, newsletterStatus} = useThemeContextProvider();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        subscribeNewsletter(email);

        if (newsletterStatus.success) {
            setEmail('');
        }
    };


    return (
        <div className={'w-auto flex flex-col items-center justify-center'}>
            <form
                onSubmit={handleSubmit}
                className={'w-auto flex flex-col md:flex-row items-center justify-between gap-3'}
            >
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Adresa de email"
                    disabled={newsletterStatus.loading}
                    className={'py-3 px-5 font-font-s text-xl  font-bold !w-[280px]'}
                />
                <button
                    type="submit"
                    disabled={newsletterStatus.loading}
                    className={'bg-olive-color py-3 px-5 uppercase text-white font-font-p  tracking-wide text-xl  font-bold tracking-wider ' +
                        'hover:bg-olive-color hover:shadow-md hover:text-white'}
                >
                    {newsletterStatus.loading ? 'Asteapta...' : 'Abonare'}
                </button>
            </form>

            {newsletterStatus.error && (
                <div className={'text-red-500 mt-2 font-font-s text-base'}>
                    {newsletterStatus.error}
                </div>
            )}

            {newsletterStatus.success && (
                <div className={'text-green-500 mt-2 font-font-s text-base'}>
                    {'Te-ai inscris cu succes!'}
                </div>
            )}
        </div>
    );
};
