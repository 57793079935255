import React from 'react';
import {StatusIcon} from "../../../../../../../general";

export const TrueFalseEvaluationView = ({testData}) => {
    return (
        <div className="flex flex-col gap-3 mt-5 mb-10 p-3 md:p-6">
            <h2 className="uppercase font-semibold text-gray-700 text-2xl">
                Adevărat/Fals - Rezultate
            </h2>

            {testData?.trueOrFalse.map((item) => (
                <div key={item.id} className="flex flex-col gap-5">
                    {item.questions.map((question) => (
                        <div key={question.id} className="p-3 border">
                            <p className="text-xl  font-bold">Întrebarea {question.id}</p>
                            <div
                                dangerouslySetInnerHTML={{__html: question.text}}
                                className="text-xl bg-white p-3 mb-4"
                            />
                            <div className="flex flex-row justify-between items-center">
                                <div>
                                    <p className="text-xl font-bold">Răspuns elev:</p>
                                    <p className="text-xl">
                                        {question.userAnswer ? 'Adevărat' : 'Fals'}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-xl font-bold">Răspuns corect:</p>
                                    <p className="text-xl">
                                        {question.right_answer ? 'Adevărat' : 'Fals'}
                                    </p>
                                </div>
                                <div className="flex items-center gap-2">
                                    <StatusIcon
                                        isCorrect={question.userAnswer === question.right_answer}
                                        size="lg"
                                    />
                                    <span>{question.score} puncte</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
