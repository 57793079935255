import React, {useState} from 'react';
import {useCustomerTestData} from '../../../state';
import {TestDetailsView} from './TestDetailsView';


interface CustomerTestData {
    entity_id: number;
    product_sku: string;
    test_type: string;
    test_increment: number;
    test_code: string;
    test_data: string;
    class_lvl_code: string;
    module_lvl_code: string;
    difficulty_lvl_code: string;
    test_score: string;
    start_time: string;
    end_time: string;
    test_for_school: number;
    created_at: string;
    displayIndex?: number;
}

export const CustomerTestTable: React.FC = () => {
    const {
        customerData,
        loading,
        error,
        currentPage,
        setCurrentPage,
        totalPages,
        paginatedTestData,
    } = useCustomerTestData();

    const [selectedTest, setSelectedTest] = useState<CustomerTestData | null>(null);
    const [selectedTestTime, setSelectedTestTime] = useState<string | null>(null);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    if (!customerData) return <p>No data available</p>;

    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleViewTest = (test: CustomerTestData) => {
        const testTime = calculateTestTime(test.start_time, test.end_time);
        setSelectedTest(test);
        setSelectedTestTime(testTime);
    };

    const handleBack = () => {
        setSelectedTest(null);
    };

    const getTestScore = (testScore: string): number | string => {
        try {
            const parsedScore = JSON.parse(testScore);
            if (parsedScore && parsedScore?.test_score) {
                return parsedScore?.test_score.total_points || '-';
            }
        } catch {

        }

        if (!isNaN(Number(testScore))) {
            return parseFloat(testScore);
        }

        return '-';
    };

    const calculateTestTime = (startTime: string, endTime: string): string => {
        if (!startTime || !endTime) return 'N/A';

        const start = new Date(startTime);
        const end = new Date(endTime);
        const diffInSeconds = Math.floor((end.getTime() - start.getTime()) / 1000);

        const minutes = Math.floor(diffInSeconds / 60);
        const seconds = diffInSeconds % 60;

        return `${minutes}m ${seconds}s`;
    };

    if (selectedTest) {
        return <TestDetailsView testData={selectedTest} onBack={handleBack} testTime={selectedTestTime} />;
    }

    return (
        <div className="max-w-[1400px] p-4">
            <h2 className="text-2xl font-bold font-font-p  tracking-wide mb-2">
                Customer: {customerData.firstname} {customerData.lastname}
            </h2>
            <p className="mb-4 font-font-p  tracking-wide ">Email: {customerData.email}</p>
            <div className="overflow-x-auto">
                <table className="w-full border-collapse border border-[#8B4513] overflow-hidden font-font-s">
                    <thead>
                    <tr className="bg-[#8B4513] text-white">
                        <th className="py-2 px-4 border border-wood-color font-font-s">Nr.</th>
                        <th className="py-2 px-4 border border-wood-color font-font-s">Cod Test</th>
                        <th className="py-2 px-4 border border-wood-color font-font-s">Clasa</th>
                        <th className="py-2 px-4 border border-wood-color font-font-s">Modul</th>
                        <th className="py-2 px-4 border border-wood-color font-font-s">Dificultate</th>
                        <th className="py-2 px-4 border border-wood-color font-font-s">Data</th>
                        <th className="py-2 px-4 border border-wood-color font-font-s">Punctaj</th>
                        <th className="py-2 px-4 border border-wood-color font-font-s">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {paginatedTestData.map((test, index) => (
                        <tr
                            key={test.entity_id}
                            className={
                                index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                            }
                        >
                            <td className="py-2 px-4 border border-wood-color font-font-s">{index + 1}</td>
                            <td className="py-2 px-4 border border-wood-color font-font-p  tracking-wide">{test.test_code}</td>
                            <td className="py-2 px-4 border border-wood-color font-font-p  tracking-wide">{test.class_lvl_code}</td>
                            <td className="py-2 px-4 border border-wood-color font-font-p  tracking-wide">{test.module_lvl_code}</td>
                            <td className="py-2 px-4 border border-wood-color font-font-p  tracking-wide">{test.difficulty_lvl_code}</td>
                            <td className="py-2 px-4 border border-wood-color font-font-s">
                                {new Date(test.created_at).toLocaleDateString()}
                            </td>
                            <td className="py-2 px-4 border border-wood-color font-font-p  tracking-wide font-bold">
                                {getTestScore(test.test_score)}
                            </td>
                            <td className="py-2 px-4 border border-wood-color font-font-s">
                                {test.test_for_school === 1 ? (
                                    <p className="px-4 py-2 bg-gray-200 text-gray-500 font-font-p  tracking-wide cursor-default">
                                        Test Scolar
                                    </p>
                                ) : (
                                    <button
                                        onClick={() => handleViewTest(test)}
                                        className={
                                            'px-4 py-2 bg-wood-color text-white disabled:opacity-50 font-font-p  tracking-wide ' +
                                            'hover:bg-olive-color hover:text-white active:bg-olive-color active:text-white focus:bg-olive-color focus:text-white'
                                        }
                                    >
                                        View
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="w-full flex flex-row items-center justify-center gap-4 mt-4">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={
                        'px-4 py-2 bg-wood-color text-white disabled:opacity-50 font-font-p  tracking-wide ' +
                        'hover:bg-olive-color hover:text-white active:bg-olive-color active:text-white focus:bg-olive-color focus:text-white'
                    }
                >
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={
                        'px-4 py-2 bg-wood-color text-white disabled:opacity-50 font-font-p  tracking-wide ' +
                        'hover:bg-olive-color hover:text-white active:bg-olive-color active:text-white focus:bg-olive-color focus:text-white'
                    }
                >
                    Next
                </button>
            </div>
        </div>
    );
};
