import React from 'react';
import {TestFeedback} from './TestFeedback';

export const TestResult = ({score, saveMessage}) => {
    const testHistory = `${window.BASE_URL}customer_account/tests/history/`;

    const scoreDetails = score.test_score;

    return (
        <div className="test-result bg-card-bg flex flex-col items-center gap-3">
            <h2 className={''}>Test Completat</h2>
            <p className="text-xl font-font-s text-center text-text-color mb-3">
                {'Poți să vezi răspunsurile și rezultatele în'} {' '}
                <a href={testHistory}
                   className="text-xl font-semibold font-font-s text-center text-peru-color mb-3 no-underline hover:underline hover:text-text-color">
                    {'istoricul testelor'} {' '}
                </a>
                {'din contul tău de client.'}
            </p>
            <div className="score-details flex flex-col gap-2 text-center">
                <p className="text-xl font-font-s">
                    Punctaj: {scoreDetails.total_points} din {scoreDetails.max_possible_points} puncte
                </p>
                <p className="text-lg font-font-s">
                    Răspunsuri corecte: {scoreDetails.correct_answers} din {scoreDetails.total_questions}
                </p>
            </div>
            <TestFeedback score={scoreDetails.score_percentage}/>
            <p>{saveMessage}</p>
        </div>
    );
};
