import React, {useState, useEffect} from "react";
import {useProductAttributes} from "../../../state";
import {CustomAttribSk} from "../Skeleton/CustomAttribSk";
import {FileDown} from 'lucide-react';
import {AnimatedLoginPrompt} from "../ProductDetails/assets/AnimatedLoginPrompt";
import {SubscriptionAccessDenied} from "../ProductDetails/assets/SubscriptionAccessDenied";

export const CustomAttributes = () => {
    const {
        customAttributes,
        findCustomAttributeValue,
        isInFreeItemsCategory,
        isLecturesCategory,
        createdAt,
        loading,
        isInTestCategory,
        testData,
        pdfMaterial
    } = useProductAttributes() || {};

    const classLvl = findCustomAttributeValue(customAttributes, 'class_lvl');
    const difficultyLvl = findCustomAttributeValue(customAttributes, 'difficulty_lvl');
    const module_lvl = findCustomAttributeValue(customAttributes, 'module_lvl');
    const isUsedForSchoolTests = findCustomAttributeValue(customAttributes, 'test_for_school') === '1';

    const isNewProduct = (createdAt) => {
        if (!createdAt) return false;
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 3);
        return new Date(createdAt) > sixMonthsAgo;
    };

    const loginUrl = `${window.BASE_URL}customer/account/login`;

    if (loading) {
        return <CustomAttribSk/>;
    }

    return (
        <div className="flex flex-col justify-start gap-3">
            <div className="flex flex-row justify-center md:justify-start">
                {isInFreeItemsCategory && (
                    <p className="react-css font-font-p  tracking-wide text-xl md:text-2xl leading-5 font-bold text-text-color text-center md:text-left bg-gift p-3 ">
                        {'Produs gratuit!'}
                    </p>
                )}
                {isNewProduct(createdAt) && (
                    <p className="react-css font-font-p  tracking-wide text-xl md:text-2xl leading-5 font-bold text-white text-center md:text-left bg-discount p-3 ">
                        {'Adaugat recent!'}
                    </p>
                )}
            </div>
            <div className={'flex flex-col gap-3'}>
                {isLecturesCategory ? (
                    <>
                        <p className="react-css font-font-p tracking-wide text-xl md:text-2xl leading-5 font-bold text-custom-black text-center md:text-left">
                            Lectie pentru: <span>{classLvl}</span>, <span>{module_lvl}</span>
                        </p>
                        {difficultyLvl && isInTestCategory && (
                            <p className="react-css font-font-p  tracking-wide text-xl md:text-2xl leading-5 font-bold text-custom-black text-center md:text-left">
                                Nivel de dificultate: <span>{difficultyLvl}</span>
                            </p>
                        )}
                    </>
                ) : (
                    <>
                        {classLvl && module_lvl && (
                            <p className="react-css font-font-p tracking-wide text-xl md:text-2xl leading-5 font-bold text-custom-black text-center md:text-left">
                                Test pentru: <span>{classLvl}</span>, <span>{module_lvl}</span>
                            </p>
                        )}
                        {difficultyLvl && isInTestCategory && (
                            <p className="react-css font-font-p  tracking-wide text-xl md:text-2xl leading-5 font-bold text-custom-black text-center md:text-left">
                                Nivel de dificultate: <span>{difficultyLvl}</span>
                            </p>
                        )}
                        {/* Add the school test message */}
                        {isUsedForSchoolTests && (
                            <div className="bg-amber-50 border-l-4 border-amber-500 p-4 my-2">
                                <p className="font-font-p text-xl text-amber-700">
                                    Acest test este folosit momentan ca test pentru școală și este utilizat pentru evaluarea elevilor în timpul orelor.
                                </p>
                                <p className="font-font-p text-xl text-amber-600 mt-2">
                                    Notă: Dacă completați testul în acest moment, rezultatul va fi salvat dar nu va fi vizibil până când testul nu își schimbă scopul.
                                </p>
                            </div>
                        )}
                    </>
                )}

                {isLecturesCategory && pdfMaterial && (
                    <div className="flex flex-col justify-center items-center md:items-start text-center md:text-left">
                        {testData === 'userNotAuthenticated' ? (
                            <a
                                href={loginUrl}
                                className="flex items-center gap-2 px-4 py-2 bg-gray-500 hover:shadow-xl hover:!text-white w-fit"
                            >
                                <FileDown size={20}/>
                                <span>Materialul este disponibil în format PDF doar dacă ești autentificat</span>
                            </a>
                        ) : testData === 'noSubscriptionAccess' ? (
                            <a
                                className="flex items-center gap-2 px-4 py-2 bg-gray-500 hover:shadow-xl hover:!text-white w-fit"
                            >
                                <FileDown size={20}/>
                                <span>Materialul este disponibil doar pentru abonați</span>
                            </a>
                        ) : pdfMaterial.file_url && (
                            <a
                                href={pdfMaterial.file_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center gap-2 px-4 py-2 bg-olive-color hover:shadow-xl hover:!text-white w-fit"
                            >
                                <FileDown size={20}/>
                                <span>Descarcă materialul în format PDF</span>
                            </a>
                        )}
                    </div>
                )}

            </div>
        </div>
    );
};
