import React from 'react';

type ExpandButtonProps = {
    isExpanded: boolean;
    onClick: () => void;
    expandedText: string;
    collapsedText: string;
};

export const ExpandButton: React.FC<ExpandButtonProps> = ({
                                                       isExpanded,
                                                       onClick,
                                                       expandedText,
                                                       collapsedText,
                                                   }) => {

    const expandedIcon = (
        <svg className="h-5 w-5 fill-current absolute left-0" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8H16" stroke="currentColor" strokeWidth="2"/> {/* '-' Icon */}
        </svg>
    );

    const collapsedIcon = (
        <svg className="h-5 w-5 fill-current absolute left-0" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8H16M8 16V0" stroke="currentColor" strokeWidth="2"/> {/* '+' Icon */}
        </svg>
    );
    return (
        <div className="react-css flex items-center justify-center md:mb-0 mb-5">
            <button
                onClick={onClick}
                className={
                    "bg-second-btn-bg hover:bg-second-btn-bg focus:bg-second-btn-bg " +
                    "font-inter font-bold text-xl text-center text-white focus:text-white hover:text-white " +
                    "shadow-second-btn-shadow transform hover:translate-y-1 mt-2 w-80 py-3 px-4 " +
                    "flex items-center justify-center space-x-2"
                }
                type="button"
            >
                <p className="react-css flex items-center justify-center space-x-9 relative">
                    {isExpanded ? expandedIcon : collapsedIcon}
                    <span>{isExpanded ? expandedText : collapsedText}</span>
                </p>
            </button>
        </div>
    );
};

