import React, {createContext, useState, useContext, useEffect, ReactNode} from 'react';
import {request} from '../../../Infrastructure';
import {useMutation} from "@tanstack/react-query";
import {subscribeToNewsletter} from '../../queries';

interface ThemeContextProviderValue {
    subscribeNewsletter: (email: string) => void;
    newsletterStatus: {
        loading: boolean;
        error: string | null;
        success: boolean;
    };
}

const ThemeContextProvider = createContext<ThemeContextProviderValue | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({children}) => {

    const newsletterMutation = useMutation({
        mutationFn: async (email: string) => {
            return request(subscribeToNewsletter, {email});
        }
    });

    const subscribeNewsletter = (email: string) => {
        newsletterMutation.mutate(email);
    };

    const contextValue: ThemeContextProviderValue = {
        subscribeNewsletter,
        newsletterStatus: {
            loading: newsletterMutation.isLoading,
            error: newsletterMutation.data?.errors?.[0]?.message || null,
            success: newsletterMutation.data?.data?.subscribeEmailToNewsletter?.status === "SUBSCRIBED"
        }
    };

    return (
        <ThemeContextProvider.Provider value={contextValue}>
            {children}
        </ThemeContextProvider.Provider>
    );
};

export const useThemeContextProvider = () => {
    const context = useContext(ThemeContextProvider);
    if (!context) {
        throw new Error('useThemeContextProvider must be used within a ThemeProvider');
    }
    return context;
};
