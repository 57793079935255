import React from 'react';
import { useWindowSize } from '../../../../general';

const BASE_PATH = '/static/frontend/Hint4/theme/ro_RO/images';

const BLOCKS = [
    { type: 'dirtnb.png', position: 0 },
    { type: 'stonenb.png', position: 1 },
    { type: 'woodnb.png', position: 2 },
    { type: 'ironnb.png', position: 3 },
    { type: 'diamondnb.png', position: 4 }
] as const;

interface BlockProps {
    type: string;
    position: number;
    isMobile: boolean;
}

const Block: React.FC<BlockProps> = ({ type, position, isMobile }) => (
    <div
        className="w-20 h-20 md:w-40 md:h-40 absolute bottom-0"
        style={{
            left: isMobile
                ? `${position * 20}%`
                : `calc(10% + ${position * 16}%)`
        }}
    >
        <img
            src={`${BASE_PATH}/${type}`}
            alt={`${type} block`}
            className="w-full h-full"
        />
    </div>
);

export const UnderConstruction: React.FC = () => {
    const { isMobile } = useWindowSize();

    return (
        <div className="min-h-[350px] xl:min-h-[800px] flex flex-col items-center justify-center p-4 font-minecraft">
            <div className="text-center mb-8">
                <h1 className="text-4xl font-bold text-text-color mb-4 font-font-p  tracking-wide">
                    Construim ceva epic!
                </h1>
                <p className="text-xl text-text-color mt-2 font-font-s">
                    Reveniți mai târziu pentru a vedea ce am construit!
                </p>
                <p className="text-xl text-text-color font-font-s">
                    Villagerii noștri lucrează din greu să termine această pagină.
                </p>
            </div>

            <div
                className="relative w-[90%] xl:w-[1200px] h-[600px] overflow-hidden"
                style={{
                    backgroundImage: `url(${BASE_PATH}/beach2.png)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
                <div className="absolute bottom-0 left-0 w-full h-40 bg-brown-600 bg-opacity-50" />

                {BLOCKS.map(block => (
                    <Block
                        key={block.position}
                        type={block.type}
                        position={block.position}
                        isMobile={isMobile}
                    />
                ))}

            </div>

            <div className="mt-8 text-center">
                <p className="text-xl text-text-color font-font-s">
                    Progres construcție: 0% 😊 😊 😊
                </p>
            </div>
        </div>
    );
};
