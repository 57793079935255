import React from 'react';

export const Mission = () => {
    return (
        <>
            <section className="bg-custom-black py-16 px-8 text-center">
                <h1 className="font-font-p  tracking-wide text-5xl mb-4 text-white">
                    Sperăm să Contribuim la Viitorul Educației
                </h1>
                <p className="font-font-s text-xl max-w-3xl mx-auto text-white">
                    Îmbinăm pasiunea pentru educație cu tehnologia modernă pentru a crea experiențe de învățare
                    memorabile și eficiente.
                </p>
            </section>

            <section className="bg-peru-color py-16 px-8 text-center text-white">
                <h2 className="font-font-p  tracking-wide text-4xl mb-8">Misiunea Noastră</h2>
                <p className="max-w-3xl mx-auto text-xl font-font-s">
                    Transformăm educația tradițională într-o aventură interactivă,
                    inspirată din universul jocurilor și animațiilor, pentru a face
                    procesul de învățare mai captivant și mai eficient pentru elevi.
                </p>
            </section>
        </>

    );
};
