import React from 'react';
import { StatusIcon } from "../../../../../../general";

export const GridTestAdminView = ({ testData }) => {
    const { gridTest } = testData;

    return (
        <div className="grid-test-container bg-card-bg xl:w-[1000px] w-full flex flex-col gap-3
                mt-5 mb-10 md:mb-15 p-3 md:p-6 border border-double border-medium-blue
                shadow-round-shadow border-x border-y"
        >
            <h2 className="uppercase font-font-p tracking-wide font-semibold text-text-color text-2xl">
                Grila - Rezultate Admin
            </h2>

            {gridTest.map((question) => (
                <div
                    key={question.question_id}
                    className="grid-test-item w-full mb-6 flex flex-col items-start justify-start
                    gap-5 p-4 bg-card-bg border border-medium-blue"
                >
                    <p className="font-font-s text-xl font-bold">
                        Întrebarea {question.question_id}
                    </p>

                    <p
                        dangerouslySetInnerHTML={{__html: question.question_text}}
                        className="font-font-s font-normal text-text-color text-xl bg-white p-3
                        rounded border border-double border-medium-blue border-x border-y w-full"
                    />

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                        {Object.entries(question.answers).map(([key, value], index) => {
                            const answerNumber = index + 1;
                            const isUserAnswer = answerNumber === question.user_answer;
                            const isCorrectAnswer = answerNumber === question.correct_answer;

                            let bgColorClass = 'border-gray-300'; // default

                            if (isUserAnswer) {
                                bgColorClass = isUserAnswer === isCorrectAnswer
                                    ? 'border-olive-color bg-olive-color/50'  // răspuns corect
                                    : 'border-peru-color bg-peru-color/50';   // răspuns greșit
                            }

                            // Pentru admin, evidențiem și răspunsul corect dacă nu e același cu cel al userului
                            if (isCorrectAnswer && !isUserAnswer) {
                                bgColorClass = 'border-blue-500 bg-blue-100';
                            }

                            return (
                                <div
                                    key={key}
                                    className={`p-3 border rounded ${bgColorClass}`}
                                >
                                    <p className="font-font-s text-lg">
                                        {value as string}
                                        {isCorrectAnswer &&
                                            <span className="ml-2 text-blue-600 font-bold">(Răspuns corect)</span>
                                        }
                                    </p>
                                </div>
                            );
                        })}
                    </div>

                    <div className="flex flex-col md:flex-row gap-3 justify-between items-center w-full">
                        <div className="flex flex-col mt-2">
                            <p className="font-font-s text-xl font-bold">Răspunsul utilizatorului:</p>
                            <p className="font-font-s font-normal text-text-color text-xl">
                                {question.user_answer
                                    ? question.answers[`answer${question.user_answer}`]
                                    : 'Nu a răspuns'}
                            </p>
                        </div>
                        <div className="flex flex-col mt-2">
                            <p className="font-font-s text-xl font-bold">Răspunsul corect:</p>
                            <p className="font-font-s font-normal text-text-color text-xl">
                                {question.answers[`answer${question.correct_answer}`]}
                            </p>
                        </div>
                        <StatusIcon
                            isCorrect={question.user_answer === question.correct_answer}
                            size="lg"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};
