import React from 'react';
import {GridEvaluationView} from "./NationalEvaluationTypes/GridEvaluationView";
import {MultiAnswerEvaluationView} from "./NationalEvaluationTypes/MultiAnswerEvaluationView";
import {TrueFalseEvaluationView} from "./NationalEvaluationTypes/TrueFalseEvaluationView";

export const NationalEvaluationTestAdminView = ({testData}) => {

    return (
        <div className="flex flex-col gap-6 p-4">
            {/* Initial Texts Section */}
            <section>
                <h2 className="text-2xl font-bold mb-4">Texte de analizat</h2>
                {testData.initialTexts[0].texts.map((text, index) => (
                    <div key={text.id} className="mb-6 p-4 bg-white  shadow">
                        <h3 className="text-xl font-semibold mb-2">Textul {index + 1}</h3>
                        <div
                            dangerouslySetInnerHTML={{__html: text.text}}
                            className="prose max-w-none"
                        />
                    </div>
                ))}
            </section>

            {/* Part A Section */}
            <section className="mt-8">
                <h2 className="text-2xl font-bold mb-4">Partea A</h2>

                {/* Multiple Answers Section */}
                {testData.partA.multiAnswers?.length > 0 && (
                    <div className="mb-6">
                        <MultiAnswerEvaluationView testData={{multipleAnswer: testData.partA.multiAnswers}}/>
                    </div>
                )}

                {/* Grid Test Section */}
                {testData.partA.gridSubjects?.length > 0 && (
                    <div className="mb-6">
                        <GridEvaluationView testData={{gridTestData: testData.partA.gridSubjects}}/>
                    </div>
                )}

                {/* True/False Section */}
                {testData.partA.trueFalseSubjects?.length > 0 && (
                    <div className="mb-6">
                        <TrueFalseEvaluationView testData={{trueOrFalse: testData.partA.trueFalseSubjects}}/>
                    </div>
                )}
            </section>

            {/* Part B Section */}
            <section className="mt-8">
                <h2 className="text-2xl font-bold mb-4">Partea B</h2>

                {testData.partB.gridSubjects?.length > 0 && (
                    <div className="mb-6">
                        <GridEvaluationView testData={{gridTestData: testData.partB.gridSubjects}}/>
                    </div>
                )}

                {testData.partB.multiAnswers?.length > 0 && (
                    <div className="mb-6">
                        <MultiAnswerEvaluationView testData={{multipleAnswer: testData.partB.multiAnswers}}/>
                    </div>
                )}
            </section>

            {/* Final Score Section */}
            <section className="mt-8 p-4 bg-white  shadow">
                <h2 className="text-2xl font-bold mb-4">Punctaj Total</h2>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    <div className="p-3 border rounded">
                        <p className="font-semibold">Subiectul 1A</p>
                        <p>{testData.score_details.subiect1A.score}/{testData.score_details.subiect1A.max_score} puncte</p>
                    </div>
                    <div className="p-3 border rounded">
                        <p className="font-semibold">Subiectul 1B</p>
                        <p>{testData.score_details.subiect1B.score}/{testData.score_details.subiect1B.max_score} puncte</p>
                    </div>
                    <div className="p-3 border rounded">
                        <p className="font-semibold">Subiectul 2</p>
                        <p>{testData.score_details.subiect2.score}/{testData.score_details.subiect2.max_score} puncte</p>
                    </div>
                    <div className="p-3 border  bg-amber-50">
                        <p className="font-semibold">Total</p>
                        <p>{testData.score_details.total.score}/{testData.score_details.total.max_score} puncte</p>
                    </div>
                </div>
            </section>
        </div>
    );
};
