import React from 'react';
import {useProductAttributes} from "../../../state";
import {VimeoVideo} from "./VimeoVideo";
import {ProductImage} from "./ProductImage";

export const GalleryRender = () => {
    const {productAttributes} = useProductAttributes() || {};
    const hasVideo = productAttributes?.media_gallery?.[0]?.video_content?.video_url;

    return (
        <div className="w-full md:max-w-[1440px] flex flex-col justify-center items-center">
            {hasVideo ? <VimeoVideo/> : <ProductImage/>}
        </div>
    );
};
