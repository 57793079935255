import React, {useEffect, useState} from 'react';
import {TrueOrFalse} from "./RoTests/types/TrueOrFalse";
import {TrueFalseTestProvider, useTrueFalseTest} from "../../state";
import {client} from "../../../Infrastructure/models/client";
import {QueryClientProvider} from "@tanstack/react-query";

const queryClient = client();

export const AdminTrueFalseForm: React.FC<{ product_id: number; productSku: string }> = ({product_id, productSku}) => {
    const {saveTest, trueFalseTestData, loading} = useTrueFalseTest();

    const [localTest, setLocalTest] = useState(() => ({
        product_id,
        productSku,
        status: 1,
        questions: Array(20).fill(null).map((_, index) => ({
            question_id: index + 1,
            product_id,
            questionText: '',
            correctAnswer: false,
        })),
    }));

    useEffect(() => {
        if (trueFalseTestData?.trueFalseTest) {
            const { trueFalseTest, trueFalseQuestions } = trueFalseTestData;
            setLocalTest({
                product_id,
                productSku,
                status: trueFalseTest.status,
                questions: trueFalseQuestions.map((q) => ({
                    question_id: q.question_id,
                    product_id,
                    questionText: q.questionText,
                    correctAnswer: q.correctAnswer,
                })),
            });
        }
    }, [trueFalseTestData, product_id, productSku]);

    const handleSave = async () => {
        if (localTest.questions.some(q => !q.questionText)) {
            alert('Toate întrebările trebuie completate!');
            return;
        }

        try {
            await saveTest({
                product_id: localTest.product_id,
                productSku: localTest.productSku,
                status: localTest.status,
                questions: localTest.questions,
            });
            alert('Test salvat cu succes!');
        } catch (error) {
            alert('Eroare la salvare: ' + error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            handleSave();
        }}>
            <div className="page-main-actions">
                <div className="page-actions">
                    <button
                        className="action-secondary back"
                        type="button"
                        onClick={() => window.history.back()}
                    >
                        Back
                    </button>
                    <button className="action-primary save" type="submit">
                        Save Test
                    </button>
                </div>
            </div>

            <div className="admin__fieldset-wrapper">
                <fieldset>
                    <div className="admin__field">
                        <label>Status</label>
                        <select
                            name="status"
                            value={localTest.status}
                            onChange={(e) =>
                                setLocalTest({...localTest, status: Number(e.target.value)})
                            }
                        >
                            <option value="1">Enabled</option>
                            <option value="0">Disabled</option>
                        </select>
                    </div>
                </fieldset>
            </div>
            <TrueOrFalse
                questions={localTest.questions}
                setQuestions={(questions) => setLocalTest({...localTest, questions})}
            />
        </form>
    );
};

export const AdminTrueFalseFormWrapper: React.FC<{ product_id: number; productSku: string }> = (props) => (
    <QueryClientProvider client={queryClient}>
        <TrueFalseTestProvider product_id={props.product_id}>
            <AdminTrueFalseForm {...props} />
        </TrueFalseTestProvider>
    </QueryClientProvider>
);
