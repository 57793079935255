import React from 'react';
import { StatusIcon } from "../../../../../../general";

export const TrueFalseTestAdminView = ({ testData }) => {
    const { trueOrFalse } = testData;

    return (
        <div className="true-false-container bg-card-bg xl:w-[1000px] w-full flex flex-col gap-3
                mt-5 mb-10 md:mb-15 p-3 md:p-6 border border-double border-medium-blue
                shadow-round-shadow border-x border-y"
        >
            <h2 className="uppercase font-font-p tracking-wide font-semibold text-text-color text-2xl">
                Adevărat sau Fals - Rezultate Admin
            </h2>

            {trueOrFalse.map((question) => (
                <div
                    key={question.question_id}
                    className="true-false-item w-full mb-6 flex flex-col items-start justify-start
                    gap-5 p-4 bg-card-bg border border-medium-blue"
                >
                    <p className="font-font-s text-xl font-bold">
                        Întrebarea {question.question_id}
                    </p>

                    <p
                        dangerouslySetInnerHTML={{__html: question.question_text}}
                        className="font-font-s font-normal text-text-color text-xl bg-white p-3
                        rounded border border-double border-medium-blue border-x border-y w-full"
                    />

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                        {[
                            { value: true, label: 'Adevărat' },
                            { value: false, label: 'Fals' }
                        ].map((option) => {
                            const isUserAnswer = question.user_answer === option.value;
                            const isCorrectAnswer = question.correct_answer === option.value;

                            let bgColorClass = 'border-gray-300'; // default

                            if (isUserAnswer) {
                                bgColorClass = isUserAnswer === isCorrectAnswer
                                    ? 'border-olive-color bg-olive-color/50'  // răspuns corect
                                    : 'border-peru-color bg-peru-color/50';   // răspuns greșit
                            }

                            // Pentru admin, evidențiem și răspunsul corect dacă nu e același cu cel al userului
                            if (isCorrectAnswer && !isUserAnswer) {
                                bgColorClass = 'border-blue-500 bg-blue-100';
                            }

                            return (
                                <div
                                    key={option.value.toString()}
                                    className={`p-3 border rounded ${bgColorClass}`}
                                >
                                    <p className="font-font-s text-lg">
                                        {option.label}
                                        {isCorrectAnswer &&
                                            <span className="ml-2 text-blue-600 font-bold">(Răspuns corect)</span>
                                        }
                                    </p>
                                </div>
                            );
                        })}
                    </div>

                    <div className="flex flex-col md:flex-row gap-3 justify-between items-center w-full">
                        <div className="flex flex-col mt-2">
                            <p className="font-font-s text-xl font-bold">Răspunsul utilizatorului:</p>
                            <p className="font-font-s font-normal text-text-color text-xl">
                                {question.user_answer === true ? 'Adevărat'
                                    : question.user_answer === false ? 'Fals'
                                        : 'Nu a răspuns'}
                            </p>
                        </div>
                        <div className="flex flex-col mt-2">
                            <p className="font-font-s text-xl font-bold">Răspunsul corect:</p>
                            <p className="font-font-s font-normal text-text-color text-xl">
                                {question.correct_answer ? 'Adevărat' : 'Fals'}
                            </p>
                        </div>
                        <StatusIcon
                            isCorrect={question.user_answer === question.correct_answer}
                            size="lg"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};
