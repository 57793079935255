import React from 'react';
import {DeletionRequestTable} from './DeletionRequestTable';
import {useDeletionRequests} from '../../../state';

export const DeletionRequestPage: React.FC = () => {
    const {deletionRequests, loading, error} = useDeletionRequests();

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div>
            <DeletionRequestTable data={deletionRequests}/>
        </div>
    );
};
