import React from 'react';
import {ProductAttributeProvider, RoGridTestProductProvider, RoTestDataProductProvider} from '../../state';
import {Description} from "./ProductDetails/Description";
import {Review} from './ProductDetails/Review';
import {ReviewForm} from './ProductDetails/ReviewForm';
import {useWindowSize} from '../../../../general';
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";
import {TestComponents} from "./ProductDetails/TestComponents";
import {AutoRelated} from "./ProductDetails/AutoRelated";
import {CheckoutProvider} from "../../../Checkout";

interface ProductSku {
    productSku: string;
    product_id: number;
}

const queryClient = client();

export const ProductDetails: React.FC<ProductSku> = ({productSku, product_id}) => {
    const {isMobile} = useWindowSize();

    return (
        <QueryClientProvider client={queryClient}>
            <CheckoutProvider>
                <ProductAttributeProvider productSku={productSku} config={{}}>
                    <RoTestDataProductProvider product_id={product_id}>
                        <RoGridTestProductProvider product_id={product_id}>
                            <div
                                className={'w-full md:max-w-[1440px] flex flex-col justify-center items-center relative'}>
                                <Description/>
                                <TestComponents/>
                                <AutoRelated/>
                                <Review/>
                                <ReviewForm productSku={productSku}/>
                            </div>
                        </RoGridTestProductProvider>
                    </RoTestDataProductProvider>
                </ProductAttributeProvider>
            </CheckoutProvider>
        </QueryClientProvider>
    );
};
