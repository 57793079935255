import React from 'react';

export const Values = () => {
    const values = [
        {
            title: "Inovație în Educație",
            description: "Folosim tehnologia și gamificarea pentru a face învățarea mai atractivă și mai eficientă."
        },
        {
            title: "Excelență Academică",
            description: "Materialele noastre sunt dezvoltate de experți în educație și aliniate la standardele educaționale."
        },
        {
            title: "Accesibilitate",
            description: "Creăm resurse educaționale care să fie ușor de înțeles și accesibile pentru toți elevii."
        },
        {
            title: "Învățare Personalizată",
            description: "Oferim experiențe de învățare adaptate nevoilor și ritmului fiecărui elev."
        }
    ];

    return (
        <section className="bg-olive-color text-white py-16 px-8">
            <h2 className="font-font-p  tracking-wide text-4xl text-center mb-12">
                Valorile Noastre
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
                {values.map((value, index) => (
                    <div
                        key={index}
                        className="bg-white bg-opacity-10 p-6   shadow-inset-effect"
                    >
                        <h3 className="font-font-p  tracking-wide text-xl mb-4">
                            {value.title}
                        </h3>
                        <p className="text-xl">
                            {value.description}
                        </p>
                    </div>
                ))}
            </div>
        </section>
    );
};
