import React from "react";
import {useProductAttributes} from "../../../state";
import {PriceInfoSk} from "../Skeleton/PriceInfoSk";

export const PriceInfo = () => {
    const {productAttributes, isInAddToCartCategory, loading} = useProductAttributes() || {};

    if (loading) {
        return <PriceInfoSk/>;
    }

    const formatPrice = (price) => {
        return new Intl.NumberFormat('ro-RO', {style: 'currency', currency: 'RON'}).format(price);
    };

    const regularPriceValue = productAttributes?.price_range?.minimum_price?.regular_price?.value;
    const finalPriceValue = productAttributes?.price_range?.minimum_price?.final_price?.value;

    const regularPriceFormatted = formatPrice(regularPriceValue);
    const finalPriceFormatted = formatPrice(finalPriceValue);

    const discountValue = regularPriceValue - finalPriceValue
    const discountValueFormatted = formatPrice(discountValue);

    if (regularPriceValue === 0 || !isInAddToCartCategory) {
        return null
    }

    return (
        <>
            {productAttributes && productAttributes && (
                <div className="p-5 flex flex-col justify-start">
                    {regularPriceValue === finalPriceValue ? (
                        <p className={'react-css text-custom-gray text-3xl font-bold font-font-p  tracking-wide flex flex-row items-end justify-start gap-2'}>
                            {regularPriceFormatted}
                            <span className={'react-css text-text-gray text-sm font-bold font-font-p  tracking-wide leading-6'}>
                                        {'TVA inclus'}
                                </span>
                        </p>
                    ) : (
                        <div className={'react-css flex flex-col w-full items-start'}>
                            <div className={'react-css flex flex-wrap justify-start gap-4 items-center w-full'}>
                                <p className={'react-css font-bold text-text-gray text-xl line-through font-font-p  tracking-wide'}>
                                    {regularPriceFormatted}
                                </p>
                                <p className={'react-css bg-peru-color p-1 xl:px-2 xl:py-1 font-font-p  tracking-wide font-bold text-white ' +
                                    'text-xl '}>
                                    {'- '} {discountValueFormatted}
                                </p>
                            </div>
                            <p className={'react-css text-3xl font-bold text-red-500 font-font-p  tracking-wide flex flex-row items-end justify-start gap-2'}>
                                {finalPriceFormatted}
                                <span className={'react-css text-text-gray text-sm font-bold font-font-p  tracking-wide leading-6'}>
                                    {'TVA inclus'}
                                </span>
                            </p>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
