import React from 'react';
import {AlertCircle, ArrowRight, DollarSign} from 'lucide-react';

export const SubscriptionAccessDenied = () => {

    const subscriptionUrl = `${window.BASE_URL}subscriptii.html`;

    return (
        <div className="subscription w-full flex flex-col items-center justify-center p-8">
            <div className="bg-card-bg shadow-round-shadow flex flex-col items-center justify-center p-8 w-full md:w-1/2">
                <div className="flex items-center justify-center mb-6">
                    <AlertCircle className="w-12 h-12 text-yellow-500 mr-4 animate-pulse"/>
                    <h2 className="text-2xl font-bold text-gray-800">Acces restricționat</h2>
                </div>
                <p className="text-xl text-center text-gray-700 mb-6">
                    Ne pare rău, dar nu ai acces la acest conținut cu abonamentul tău actual.
                </p>
                <div className="mt-6 flex flex-col items-center animate-fade-in">
                    <p className="text-xl font-semibold text-gray-800 mb-4">
                        Activează abonamentul dorit
                    </p>
                    <a href={subscriptionUrl} className="no-underline hover:no-underline">
                        <button
                            className="bg-olive-color hover:bg-olive-color text-white font-bold py-3 px-6 flex items-center
                            hover:text-white focus:bg-olive-color active:olive-color focus:text-white active:text-white
                            transition duration-300 ease-in-out transform hover:scale-105 gap-2">
                            Vezi abonamente disponibile
                            <ArrowRight className="w-5 h-5"/>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};
