import React from 'react';
import {AlertCircle, Play, ArrowRight} from 'lucide-react';

export const VideoSubscriptionRequired = () => {
    const subscriptionUrl = `${window.BASE_URL}subscriptii.html`;

    return (
        <div className="w-full flex flex-col items-center justify-center p-4 md:p-8">
            <div
                className="bg-card-bg shadow-round-shadow flex flex-col items-center justify-center p-8 w-full md:w-2/3"
            >
                <div className="flex items-center justify-center mb-6">
                    <AlertCircle className="w-12 h-12 text-yellow-500 mr-4"/>
                    <Play className="w-10 h-10 text-gray-400"/>
                </div>
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                    Video Premium
                </h2>
                <p className="text-xl text-center text-gray-700 mb-6">
                    Acest conținut video este disponibil exclusiv pentru abonați.
                </p>
                <div className="flex flex-col items-center">
                    <p className="text-xl font-semibold text-center text-text-color mb-6">
                        Activează abonamentul dorit pentru a avea acces la materialele video.
                    </p>
                    <a href={subscriptionUrl} className="no-underline hover:no-underline">
                        <button
                            className="bg-olive-color hover:bg-olive-color text-white font-bold py-3 px-6 flex items-center
                            hover:text-white focus:bg-olive-color active:olive-color focus:text-white active:text-white
                            transition duration-300 ease-in-out transform hover:scale-105 gap-2">
                            Vezi abonamente disponibile
                            <ArrowRight className="w-5 h-5"/>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};
