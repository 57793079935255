import React from 'react';
import {Stars} from "../../../../../general";
import {useProductAttributes} from '../../../state';
import {DescriptionSk} from "../Skeleton/DescriptionSk";

const preprocessReviews = (reviews) => {
    return reviews.map(review => {
        const dateOnly = review.created_at.split(" ")[0];
        const defaultRating = {
            name: 'default_code',
            value: '5',
        };

        return {
            ...review,
            created_at: dateOnly,
            average_rating: review.ratings_breakdown.length > 0 ? review.average_rating : 5,
            ratings_breakdown: review.ratings_breakdown.length > 0 ? review.ratings_breakdown : [defaultRating],
        };
    });
};

export const Review = () => {
    const {
        productAttributes,
        loading,
        productReviews,
        nextReviewsPage,
        prevReviewsPage,
        currentReviewPage,
        totalReviewPages
    } = useProductAttributes() || {};

    if (loading) {
        return <DescriptionSk/>;
    }

    const preprocessedReviews = preprocessReviews(productReviews);
    const numberOfReviews = preprocessedReviews.length;

    const totalStarsValue = preprocessedReviews.reduce((sum, review) => {
        const reviewStars = review.ratings_breakdown.reduce((rSum, rating) => rSum + Number(rating.value), 0) / review.ratings_breakdown.length;
        return sum + reviewStars;
    }, 0);

    const averageStars = numberOfReviews > 0 ? parseFloat((totalStarsValue / numberOfReviews).toFixed(1)) : 0;

    return (
        <div className={'bg-card-bg react-css mb-5 mt-5 md:mb-10 md:mt-10 p-3 md:p-6 w-full shadow-round-shadow w-full md:w-1/2 ' +
            'border-2 border-double border-medium-blue border-x border-y'}
             id="productReviews">
            <div className={'relative uppercase text-text-color w-full'}>
                <h2
                    className={'relative uppercase font-inter font-semibold leading-[50px] text-text-color text-2xl pl-10 pr-10'}>
                    {'Evaluari'}
                </h2>
            </div>
            {numberOfReviews === 0 ? (
                <p className={'react-css text-center font-font-s text-custom-black text-xl mt-5'}>
                    {'Nu exista evaluari pentru acest produs'}
                </p>
            ) : (
                <div className={'react-css overflow-auto max-h-[800px] border-2 border-solid border-cards-bg p-3 '}>
                    <div className={'react-css'}>
                        <h2 className={'react-css text-xl font-black text-left text-custom-black font-font-p  tracking-wide mb-8 flex'}>
                        <span className={'react-css md:block hidden mr-2'}>
                            {'Evaluari pentru '}
                        </span>
                            <span
                                className={'react-css text-custom-black font-font-p  tracking-wide font-bold text-center text-xl'}>
                            {productAttributes?.name}
                        </span>
                        </h2>
                    </div>
                    <div
                        className={'react-css border-b-2 mb-8 flex flex-col md:flex-row md:flex-row items-center justify-start md:gap-12 gap-5 pb-5'}>
                        <div className={'react-css flex flex-row items-center justify-start gap-5 '}>
                            <p className={'react-css text-custom-black font-font-p  tracking-wide text-6xl md:text-8xl  font-black leading-none tracking-normal mr-6'}>
                                {averageStars}
                            </p>
                            <div className={'react-css flex flex-col items-start justify-start gap-2'}>
                                    <span
                                        className={'react-css flex flex-row items-center justify-start gap-1 text-custom-black'}>
                                        <Stars totalStars={averageStars} starSize={'h-10 w-10 md:h-12 md:w-12'}/>
                                    </span>
                                <p className={'react-css flex flex-row gap-2 items-center text-custom-black font-font-p  tracking-wide text-xl font-bold leading-tight tracking-normal'}>
                                <span className={'react-css font-font-p  tracking-wide text-xl font-bold'}>
                                    {'din '}{numberOfReviews}
                                </span>
                                    {'Evaluari'}
                                </p>
                            </div>
                        </div>
                        <div className={'react-css flex flex-col items-start justify-start gap-3'}>
                            <button onClick={() => window.location.href = '#review_form_react_app'}
                                    className={'bg-olive-color hover:bg-olive-color hover:text-white text-white font-bold py-2 px-4 ' +
                                        'focus:bg-olive-color active:olive-color focus:text-white active:text-white ' +
                                        'flex items-center transition duration-300 ease-in-out transform hover:scale-105'}
                                    type="button"
                            >
                                <p className="react-css ">
                                    {'Scrie o evaluare'}
                                </p>
                            </button>
                        </div>
                    </div>

                    {preprocessedReviews.map((review, index) => (
                        <div className={'mb-8 border-b-2 flex flex-col'} key={index}>
                            <div
                                className={'flex md:flex-col flex-row gap-3 items-start justify-start md:w-3/12 mr-3 md:mb-0 mb-3'}>
                            <span
                                className={'react-css font-font-s font-bold text-custom-black text-xl'}>
                                {review.nickname}
                            </span>
                                <span
                                    className={'react-css text-xl font-font-s font-normal'}>
                                {review.created_at}
                            </span>
                            </div>
                            <div className={'flex flex-row gap-3 md:w-9/12 items-center justify-start mb-5'}>
                                <h2 className={'react-css font-font-p  tracking-wide font-bold text-custom-black text-xl'}>{review.summary}</h2>
                                <div className={''}>
                                    {review.ratings_breakdown.map((rating, ratingIndex) => (
                                        <div className={'flex flex-row items-center justify-start space-x-4'}
                                             key={ratingIndex}>
                                            <Stars totalStars={Number(rating.value)}
                                                   starSize={'h-5 w-5 md:h-10 md:w-10'}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <p className={'react-css font-font-s text-xl font-normal text-custom-black mb-5'}>{review.text}</p>
                        </div>
                    ))}
                    {numberOfReviews > 0 && (
                        <div className="pagination-controls flex justify-between items-center mt-4">
                            <button
                                onClick={prevReviewsPage}
                                disabled={currentReviewPage === 1}
                                className="px-4 py-2 bg-btn-color hover:bg-btn-hover focus:bg-btn-hover text-white disabled:opacity-50"
                            >
                                {'<'}
                            </button>
                            <p className="text-custom-black font-font-s">
                                Pagina {currentReviewPage} din {totalReviewPages}
                            </p>
                            <button
                                onClick={nextReviewsPage}
                                disabled={currentReviewPage === totalReviewPages}
                                className="px-4 py-2 bg-btn-color hover:bg-btn-hover focus:bg-btn-hover text-white disabled:opacity-50"
                            >
                                {'>'}
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
