import React, {createContext, useContext, useState, ReactNode, useEffect, useMemo} from 'react';
import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query';
import {request} from '../../../Infrastructure';
import {adminCustomerTestData, updateTestData} from '../../queries';

interface AdminCustomerTestData {
    entity_id: number;
    customer_id: number;
    customer_email: string;
    product_sku: string;
    test_type: string;
    test_increment: number;
    test_code: string;
    test_data: string;
    test_for_school: number;
    class_lvl_code: string;
    module_lvl_code: string;
    difficulty_lvl_code: string;
    test_score: string;
    created_at: string;
    correction_type: string;
}

interface UpdateTestDataInput {
    entity_id: number;
    test_score?: string;
    test_data?: string;
    test_for_school?: number;
    correction_type?: string;
}

interface AdminTestDataContextValue {
    adminTestData: AdminCustomerTestData[];
    getProcessedTestData: (testData: string) => any;
    loading: boolean;
    error: any;
    updateError: any;
    handleUpdateTestData: (input: UpdateTestDataInput) => Promise<void>;
    // filtrare și paginare
    currentPage: number;
    totalPages: number;
    dateFrom: string | null;
    dateTo: string | null;
    handlePageChange: (page: number) => void;
    handleDateFilterChange: (dateFrom: string | null, dateTo: string | null) => void;
    paginatedData: AdminCustomerTestData[];
}

const AdminTestDataContext = createContext<AdminTestDataContextValue | undefined>(undefined);

export const AdminTestDataProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [adminTestData, setAdminTestData] = useState<AdminCustomerTestData[]>([]);
    const queryClient = useQueryClient();

    const [dateFrom, setDateFrom] = useState<string | null>(() => {
        const date = new Date();
        date.setDate(date.getDate() - 10);
        return date.toISOString().split('T')[0];
    });
    const [dateTo, setDateTo] = useState<string | null>(() => {
        return new Date().toISOString().split('T')[0];
    });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;

    const processTestData = (testData: string) => {
        try {
            return JSON.parse(testData);
        } catch (error) {
            console.error('Error parsing test data:', error);
            return null;
        }
    };

    const adminTestDataQuery = useQuery({
        queryKey: ['adminTestData', dateFrom, dateTo],
        queryFn: async () => {
            return request(adminCustomerTestData, {
                variables: {
                    dateFrom: dateFrom || undefined,
                    dateTo: dateTo || undefined
                }
            });
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const updateTestDataMutation = useMutation({
        mutationFn: async (input: UpdateTestDataInput) => {
            return request(updateTestData, {input});
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['adminTestData']});
        },
    });

    const handleUpdateTestData = async (input: UpdateTestDataInput) => {
        try {
            await updateTestDataMutation.mutateAsync(input);
        } catch (error) {
            console.error('Failed to update test data:', error);
            throw error;
        }
    };

    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return adminTestData.slice(startIndex, endIndex);
    }, [adminTestData, currentPage]);

    const totalPages = useMemo(() => {
        return Math.ceil(adminTestData.length / itemsPerPage);
    }, [adminTestData]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleDateFilterChange = (newDateFrom: string | null, newDateTo: string | null) => {
        setDateFrom(newDateFrom);
        setDateTo(newDateTo);
        setCurrentPage(1); // Reset to first page when changing filters
    };

    useEffect(() => {
        if (adminTestDataQuery?.data) {
            setAdminTestData(adminTestDataQuery?.data?.data?.adminCustomerTestData);
        }
    }, [adminTestDataQuery?.data]);

    return (
        <AdminTestDataContext.Provider
            value={{
                adminTestData,
                getProcessedTestData: processTestData,
                loading: adminTestDataQuery?.isLoading || updateTestDataMutation.isLoading,
                error: adminTestDataQuery?.error,
                updateError: updateTestDataMutation.error,
                handleUpdateTestData,
                currentPage,
                totalPages,
                dateFrom,
                dateTo,
                handlePageChange,
                handleDateFilterChange,
                paginatedData
            }}
        >
            {children}
        </AdminTestDataContext.Provider>
    );
};

export const useAdminTestData = () => {
    const context = useContext(AdminTestDataContext);
    if (!context) {
        throw new Error('useAdminTestData must be used within an AdminTestDataProvider');
    }
    return context;
};
