import React, { useCallback } from 'react';
import { QuillEditor } from "../assets/QuillEditor";

export const GridTest = ({ questions, setQuestions }) => {
    const updateQuestionText = useCallback((questionId, content) => {
        setQuestions(questions.map(q =>
            q.question_id === questionId ? { ...q, questionText: content } : q
        ));
    }, [questions, setQuestions]);

    const updateAnswer = useCallback((questionId, answerNumber, value) => {
        setQuestions(questions.map(q =>
            q.question_id === questionId
                ? { ...q, [`answer${answerNumber}`]: value }
                : q
        ));
    }, [questions, setQuestions]);

    const updateCorrectAnswer = useCallback((questionId, correctAnswer) => {
        setQuestions(questions.map(q =>
            q.question_id === questionId ? { ...q, correctAnswer } : q
        ));
    }, [questions, setQuestions]);

    return (
        <div className="flex flex-col gap-8">
            <h2 className="text-2xl font-semibold mb-4">Întrebări</h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                {questions.map((question, index) => (
                    <div
                        key={`question-${question.question_id}-${index}`}
                        className="border-b border-gray-200 pb-8"
                    >
                        <h3 className="text-xl font-semibold mb-4">
                            Întrebarea {index + 1}
                        </h3>
                        <QuillEditor
                            key={`editor-${question.question_id}`}
                            value={question.questionText}
                            onEditorChange={(content) => updateQuestionText(question.question_id, content)}
                            placeholder={`Introduceți textul pentru întrebarea ${index + 1}`}
                            className="bg-white h-96 mb-20"
                        />
                        <div className="space-y-4">
                            {[1, 2, 3, 4].map((answerNum) => (
                                <div key={answerNum} className="flex items-center gap-4">
                                    <input
                                        type="radio"
                                        name={`answer-${question.question_id}`}
                                        checked={question.correctAnswer === answerNum}
                                        onChange={() => updateCorrectAnswer(question.question_id, answerNum)}
                                        className="w-4 h-4"
                                    />
                                    <input
                                        type="text"
                                        value={question[`answer${answerNum}`] || ''}
                                        onChange={(e) => updateAnswer(question.question_id, answerNum, e.target.value)}
                                        placeholder={`Răspuns ${answerNum}`}
                                        className="flex-1 border p-2 rounded"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
