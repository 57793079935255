import React, {useState} from 'react';
import {useProductAttributes} from '../../../state';
import {Stars} from "../../../../../general";
import {InfoReviewsSk} from "../Skeleton/InfoReviewsSk";
import {AddReviewButton} from "../AddToCart/AddReviewButton";

interface Review {
    summary: string;
    text: string;
    nickname: string;
    created_at: string;
    average_rating: number;
    ratings_breakdown: {
        name: string;
        value: string;
    }[];
}

const preprocessReviews = (reviews: Review[]): Review[] => {
    return reviews.map(review => {
        const dateOnly = review.created_at.split(" ")[0];
        const defaultRating = {
            name: 'default_code',
            value: '5',
        };

        return {
            summary: review.summary,
            text: review.text,
            nickname: review.nickname,
            created_at: dateOnly,
            average_rating: review.ratings_breakdown.length > 0 ? review.average_rating : 5,
            ratings_breakdown: review.ratings_breakdown.length > 0 ? review.ratings_breakdown : [defaultRating],
        };
    });
};

export const InfoReviews = () => {
    const {productReviews, loading} = useProductAttributes() || {};
    const preprocessedReviews = preprocessReviews(productReviews || []);
    const numberOfReviews = preprocessedReviews.length;
    const [isHovering, setIsHovering] = useState(false);

    const totalStarsValue = preprocessedReviews.reduce((sum, review) => {
        const reviewStars = review.ratings_breakdown.reduce((rSum, rating) => rSum + Number(rating.value), 0) / review.ratings_breakdown.length;
        return sum + reviewStars;
    }, 0);

    const averageStars = parseFloat((totalStarsValue / numberOfReviews).toFixed(1));

    if (loading) {
        return <InfoReviewsSk/>;
    }

    return (
        <div className="relative"
             onMouseEnter={() => setIsHovering(true)}
             onMouseLeave={() => setIsHovering(false)}
        >
            {!productReviews || productReviews?.length === 0 ? (
               <AddReviewButton/>
            ) : (
                <div
                    className={'react-css flex flex-row items-center md:justify-start justify-center gap-1 text-custom-gray '}>
                    <div
                        className="react-css flex flex-row items-center md:justify-start justify-center gap-1 text-custom-gray">
                        <a
                            href={'#productReviews'}
                            className="relative flex flex-row items-center gap-1 text-custom-gray"
                            onMouseEnter={() => setIsHovering(true)}
                            onMouseLeave={() => setIsHovering(false)}
                        >
                            <Stars totalStars={averageStars} starSize="h-8 w-8"/>
                            <span className="flex-row items-center font-bold text-custom-gray text-xl">
                            {`(${numberOfReviews})`}
                        </span>
                            {isHovering && (
                                <div
                                    className="absolute left-0 bottom-0 transform translate-y-full mt-2 p-2 bg-text-color border border-gray-200 shadow-lg z-10">
                                    <p className={'text-xl font-font-s text-white'}>{'Vezi toate evaluările'}</p>
                                </div>
                            )}
                        </a>
                        <a
                            className="react-css flex flex-row items-center justify-start xl:justify-start font-font-p  tracking-wide text-xl hover:underline gap-2 ml-3 font-bold text-custom-gray focus:text-custom-gray active:text-custom-gray visited:text-custom-gray hover:text-custom-gray"
                            href={'#review_form_react_app'}
                        >
                            {'Adauga o evaluare'}
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
}
