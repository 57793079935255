import React, {useState, useEffect} from 'react';
import ReactQuill from 'react-quill-new';
import 'react-quill/dist/quill.snow.css';

interface QuillEditorProps {
    value: string;
    onEditorChange?: (content: string) => void;
    placeholder?: string;
    className?: string;
}

const modules = {
    toolbar: [
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{'align': []}],
        ['link'],
        ['clean'],
        [{'color': []}, {'background': []}],
        [{'font': []}],
    ]
};

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'align',
    'link',
    'color', 'background',
    'font'
];

export const QuillEditor: React.FC<QuillEditorProps> = ({value, onEditorChange, placeholder, className}) => {
    const [internalValue, setInternalValue] = useState(value);

    useEffect(() => {
        if (value !== internalValue) {
            setInternalValue(value);
        }
    }, [value]);

    const handleChange = (content: string) => {
        // Prevenim actualizări cu conținut gol dacă deja avem conținut
        if (content === '<p><br></p>' && internalValue && internalValue !== '<p><br></p>') {
            return;
        }

        // Prevenim actualizări dacă conținutul nu s-a schimbat
        if (content === internalValue) {
            return;
        }

        setInternalValue(content);
        if (onEditorChange) {
            onEditorChange(content);
        }
    };

    return (
        <ReactQuill
            theme="snow"
            value={internalValue || ''}
            onChange={handleChange}
            modules={modules}
            formats={formats}
            placeholder={placeholder}
            className={className}
        />
    );
};
