import React from 'react';
import {QueryClientProvider} from "@tanstack/react-query";
import {AdminTestDataProvider} from "../../../state";
import {TestDataTable} from "./TestDataTable";
import {client} from "../../../../Infrastructure/models/client";

const queryClient = client();

export const AdminTestDataApp=() => {
    return (
        <QueryClientProvider client={queryClient}>
            <AdminTestDataProvider>
                <TestDataTable />
            </AdminTestDataProvider>
        </QueryClientProvider>
    );
}
