import React from 'react';
import Marquee from 'react-fast-marquee';

interface CustomMarqueeProps {
    text: string;
    speed?: number;
    gradient?: boolean;
    gradientWidth?: number;
    className?: string;
}

export const CustomMarquee: React.FC<CustomMarqueeProps> = (
    {
        text,
        speed = 50,
        gradient = false,
        gradientWidth = 10,
        className = ''
    }) => (
    <div className={`marquee-container ${className}`}>
        <Marquee
            gradient={gradient}
            gradientWidth={gradientWidth}
            speed={speed}
            delay={0}
        >
            <div
                className="marquee-content w-full font-font-p  tracking-wide text-2xl text-white flex items-center justify-center px-4 h-[30px]">
                {text}
            </div>
        </Marquee>
    </div>
);
