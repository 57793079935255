import React from 'react';
import {TrueOrFalseTestView} from './TrueOrFalseTestView';
import {GridTestView} from './GridTestView';
import {MultipleAnswerTestView} from './MultipleAnswerTestView';
import {UnderConstruction} from "../../../../StaticPages";
import {NationalEvaluationTestView} from "./NationalEvaluationTestView";
import {AlertTriangle} from "lucide-react";

export const TestDetailsView = ({testData, onBack, testTime}) => {
    if (!testData) {
        return <div>No test data available</div>;
    }

    const renderTestContent = () => {
        const parsedData = JSON.parse(testData.test_data);

        switch (testData.test_type) {
            case 'trueOrFalse':
                return <TrueOrFalseTestView data={parsedData}/>;
            case 'gridTest':
                return <GridTestView data={parsedData}/>;
            case 'multiple_answer':
                return <MultipleAnswerTestView data={parsedData}/>;
            case 'nationalEvaluation':
                return <NationalEvaluationTestView data={parsedData}/>;
            default:
                return (
                    <div>
                        <p>Inca nu s-a implementat acest tip de test</p>
                        <UnderConstruction/>
                    </div>
                )
        }
    };

    const getCorrectionMessage = (correctionType) => {
        switch (correctionType) {
            case 'auto':
                return (
                    <p className="text-font-s text-custom-black text-xl">
                        Acest test a fost evaluat automat de sistem, comparând răspunsurile tale cu răspunsurile corecte.
                    </p>
                );
            case 'ai_evaluation':
                return (
                    <p className="text-font-s text-custom-black text-xl">
                        Acest test a fost evaluat de inteligența artificială. Testul poate fi reevaluat de un profesor.
                    </p>
                );
            case 'pro_evaluation':
                return (
                    <p className="text-font-s text-custom-black text-xl">
                        Acest test a fost reevaluat de către un profesor.
                    </p>
                );
            default:
                return (
                    <p className="text-font-s text-custom-black text-xl">
                        Acest test nu a fost evaluat.
                    </p>
                );
        }
    };

    const renderScoreDetails = (testScore) => {
        if (!testScore) return null;

        const parsedScore = JSON.parse(testScore);

        return (
            <div className="score-details p-4 bg-card-bg xl:w-[1000px] border border-gray-300 rounded-lg">
                <h2 className="text-xl font-bold mb-3">Detalii punctaj</h2>
                <p><strong>Total cerinte:</strong> {parsedScore.test_score.total_questions}</p>
                <p><strong>Răspunsuri corecte:</strong> {parsedScore.test_score.correct_answers}</p>
                <p><strong>Răspunsuri greșite:</strong> {parsedScore.test_score.incorrect_answers}</p>
                <p><strong>Punctaj total:</strong> {parsedScore.test_score.total_points}</p>

                <p><strong>Timp de rezolvare:</strong> {testTime}</p>
            </div>
        );
    };

    const correctionMessage = getCorrectionMessage(testData.correction_type);

    return (
        <div className="test-details-view text-font-s">
            <div className="flex flex-col items-start gap-3 mb-4">
                <h1 className="text-2xl font-bold mb-2 text-font-s">Detalii Test</h1>
                <p className="text-font-s"><strong>Cod Test:</strong> {testData.test_code}</p>
                <p className="text-font-s">
                    <strong>Data:</strong> {new Date(testData.created_at).toLocaleDateString()}</p>
                <div className="flex flex-row gap-2 p-4 bg-amber-50 border border-amber-200 text-amber-800">
                    <AlertTriangle className="w-5 h-5 mt-1 flex-shrink-0 text-amber-500" />
                    {correctionMessage}
                </div>
            </div>

            {renderScoreDetails(testData.test_score)}

            <button
                onClick={onBack}
                className="mt-4 px-4 py-2 bg-wood-color text-white hover:bg-[#A0522D]"
            >
                Înapoi la lista de teste
            </button>

            {renderTestContent()}

            <button
                onClick={onBack}
                className="mt-4 px-4 py-2 bg-wood-color text-white hover:bg-[#A0522D]"
            >
                Înapoi la lista de teste
            </button>
        </div>
    );
};
