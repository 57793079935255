import React, { useState, useEffect } from 'react';
import { useTestDataState } from '../../../../state';
import { QuillEditor } from "../assets/QuillEditor";

interface Answer {
    id: number;
    words: string[];
}

interface MultipleAnswerData {
    product_id: number;
    product_sku?: string;
    multipleAnswer: {
        id: number;
        text: string;
        subpuncte: {
            id: number;
            text: string;
            answers: [Answer, Answer];
        }[];
    }[];
}

const createInitialData = (productId: number): MultipleAnswerData => ({
    product_id: productId,
    multipleAnswer: [{
        id: 1,
        text: '',
        subpuncte: Array(20).fill(null).map((_, index) => ({
            id: index + 1,
            text: '',
            answers: [
                { id: 1, words: [''] },
                { id: 2, words: [''] }
            ]
        }))
    }]
});

export const MultipleAnswer: React.FC<{ productId: number }> = ({ productId }) => {
    const { multipleAnswerData, handleSaveMultipleAnswer, loading, error } = useTestDataState();
    const [localData, setLocalData] = useState<MultipleAnswerData>(() => createInitialData(productId));

    useEffect(() => {
        if (multipleAnswerData?.multipleAnswer) {
            setLocalData(multipleAnswerData);
        } else if (multipleAnswerData?.product_id) {
            setLocalData(createInitialData(multipleAnswerData.product_id));
        }
    }, [multipleAnswerData]);

    const updateMainText = (content: string) => {
        setLocalData(prev => ({
            ...prev,
            multipleAnswer: [{
                ...prev.multipleAnswer[0],
                text: content
            }]
        }));
    };

    const updateQuestionText = (subpunctId: number, content: string) => {
        setLocalData(prev => ({
            ...prev,
            multipleAnswer: [{
                ...prev.multipleAnswer[0],
                subpuncte: prev.multipleAnswer[0].subpuncte.map(sp =>
                    sp.id === subpunctId ? { ...sp, text: content } : sp
                )
            }]
        }));
    };

    const updateAnswer = (subpunctId: number, answerId: number, value: string) => {
        setLocalData(prev => {
            const updatedSubpuncte = prev.multipleAnswer[0].subpuncte.map(sp => {
                if (sp.id === subpunctId) {
                    const newAnswers: [Answer, Answer] = [
                        answerId === 1 ? { ...sp.answers[0], words: [value] } : sp.answers[0],
                        answerId === 2 ? { ...sp.answers[1], words: [value] } : sp.answers[1]
                    ];
                    return { ...sp, answers: newAnswers };
                }
                return sp;
            });

            return {
                ...prev,
                multipleAnswer: [{
                    ...prev.multipleAnswer[0],
                    subpuncte: updatedSubpuncte
                }]
            };
        });
    };

    const handleSave = () => {
        if (localData) {
            handleSaveMultipleAnswer(localData, () => {
                window.location.reload();
            });
        }
    };

    if (loading) return <div>Se încarcă...</div>;
    if (error) return <div>Eroare: {error instanceof Error ? error.message : 'Eroare necunoscută'}</div>;

    const currentTest = localData.multipleAnswer[0];

    return (
        <div className="w-full">
            <h1 className="text-4xl font-bold text-amber-800 mb-5">
                Teste cu răspunsuri multiple
            </h1>

            <div className="flex flex-col gap-8">
                <section>
                    <h2 className="text-2xl font-semibold mb-4">Text inițial</h2>
                    <QuillEditor
                        value={currentTest.text}
                        onEditorChange={updateMainText}
                        placeholder="Introduceți textul inițial"
                        className="bg-white  h-96 mb-20"
                    />
                </section>

                <section>
                    <h2 className="text-2xl font-semibold mb-4">Întrebări și răspunsuri</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {currentTest.subpuncte.map((subpunct) => (
                            <div key={subpunct.id} className="border-b border-gray-200 pb-8">
                                <h3 className="text-xl font-semibold mb-4">
                                    Întrebarea {subpunct.id}
                                </h3>
                                <QuillEditor
                                    value={subpunct.text}
                                    onEditorChange={(content) => updateQuestionText(subpunct.id, content)}
                                    placeholder={`Introduceți textul pentru întrebarea ${subpunct.id}`}
                                    className="bg-white  h-96 mb-20"
                                />
                                <div className="space-y-4">
                                    {subpunct.answers.map((answer) => (
                                        <div key={answer.id}>
                                            <input
                                                type="text"
                                                value={answer.words[0] || ''}
                                                onChange={(e) => updateAnswer(subpunct.id, answer.id, e.target.value)}
                                                placeholder={`Răspunsuri ${answer.id}`}
                                                className="w-full border-2 border-gray-300 p-2 rounded"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </div>

            <div className="flex justify-end mt-8">
                <button
                    onClick={handleSave}
                    className="px-6 py-3 bg-amber-800 text-white   hover:bg-amber-700 transition-colors"
                >
                    Salvează testul
                </button>
            </div>
        </div>
    );
};
