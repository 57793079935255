import React, {useMemo,} from 'react';
import {useProductAttributes} from '../../../state';
import {ShortDescrSk} from "../Skeleton/ShortDescrSk";

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const ShortDescription = () => {
    const {productShortDescription, loading} = useProductAttributes() || {};

    const decodedShortDescription = useMemo(() => {
        return productShortDescription ? decodeHtml(productShortDescription) : null;
    }, [productShortDescription]);

    if (!productShortDescription) {
        return null;
    }

    if (loading) {
        return <ShortDescrSk/>;
    }

    return (
        <div className='relative p-5 flex flex-col justify-start' id={'productShortDescription'}>
            <div
                className={'font-font-s text-text-color text-xl'}
                dangerouslySetInnerHTML={{__html: decodedShortDescription}}>
            </div>
        </div>
    );
};
